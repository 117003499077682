import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Progress, ProgressData } from '../data/progress';
import { ApiResponse } from 'app/@core/data/api-response';
import { Lesson } from 'app/@core/data/courses';

@Injectable()
export class ProgressService extends ProgressData {

  baseUrl: string = environment.baseUrl + 'courses/progress/';
  defaultProgres:Progress = {'nextsteps':{},'modules':{},'masterclasses':{},'lessons':{}};
  private _progress = new BehaviorSubject<Progress>(this.defaultProgres);
  private dataStore: { progress:any } = { progress: this.defaultProgres};
  readonly progress = this._progress.asObservable();

  constructor(
    private http: HttpClient,
  ) {
    super()
  }

  load(cid: number):void
  {
    this.http.get<ApiResponse>(this.baseUrl + cid).subscribe({
      next: (result:ApiResponse) => {
        if(result.status == 'ok') {
          this.dataStore.progress = result.data;
          this._progress.next(Object.assign({}, this.dataStore).progress);
        }
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  unload():void
  {
    this.dataStore.progress = [];
    this._progress.next(Object.assign({}, this.dataStore).progress);
  }

  getModuleLessonProgress(lessons:Lesson[]):number
  {
    let lsnlngth = lessons.length;
    let lsncmptld = 0;
    lessons.forEach((lesson:Lesson, index:number) => {
      if(this.dataStore.progress.lessons.hasOwnProperty(lesson.id)) {
        const lsnpgrs = this.dataStore.progress.lessons[lesson.id];
        lsncmptld+= lsnpgrs.status == 'Completed' ? 1 : 0;
      }
    });
    return lsncmptld;
  }

  async markLessonStarted(cid:number,lid:number):Promise<boolean>
  {
    let promise:boolean = await new Promise((resolve, reject) => {
      if(!this.dataStore.progress.lessons.hasOwnProperty(lid)) {
        const lp = {"id":lid, "status":"Started","start_date": new Date().toISOString(),"end_date":""};
        const postdata = { 'course_id':cid, "lessons" : lp };
        this.http.post<ApiResponse>(this.baseUrl +'lessons', postdata)
        .toPromise()
        .then(result => {
          // this.dataStore.progress.lessons = result.data;
          this.dataStore.progress.lessons[lid] = lp;
          this._progress.next(Object.assign({}, this.dataStore).progress);
          resolve(true);
        },
        error => {
          reject(false);
        });
      }
      resolve(true);
    });
    return promise;
  }

  async markLessonComplete(cid:number, lid:number):Promise<boolean> {
    let promise:boolean = await new Promise((resolve, reject) => {
      if(this.dataStore.progress.lessons.hasOwnProperty(lid)) {
        const lp = Object.assign(this.dataStore.progress.lessons[lid], {"status":"Completed","end_date":new Date().toISOString()});
        const putdata = { 'course_id':cid, "lessons" : lp };
        this.dataStore.progress.lessons[lid] = lp;
        this.http.put<ApiResponse>(this.baseUrl +'lessons', putdata)
        .toPromise()
        .then(result => {
          // this.dataStore.progress.lessons = result.data;
          this.dataStore.progress.lessons[lid] = lp;
          this._progress.next(Object.assign({}, this.dataStore).progress);
          resolve(true);
        },
        error => {
          reject(false);
        });
      }
      resolve(true);
    });
    return promise;
  }

  async markMasterClassLessonStarted(cid:number,lid:number):Promise<boolean> {
    let promise:boolean = await new Promise((resolve, reject) => {
      if(!this.dataStore.progress.masterclasses.hasOwnProperty(lid)) {
        const mc = {"id":lid, "status":"Started","start_date": new Date().toISOString(),"end_date":""};
        const postdata = { 'course_id':cid, "masterclasses" : mc };
        this.http.post<ApiResponse>(this.baseUrl +'masterclasses', postdata)
        .toPromise()
        .then(result => {
          this.dataStore.progress.masterclasses[lid] = mc;
          this._progress.next(Object.assign({}, this.dataStore).progress);
          resolve(true);
        },
        msg => {
          resolve(false);
          // Error
        });
      }
      resolve(true);
    });
    return promise;
  }

  async markMasterClassLessonComplete(cid:number,lid:number):Promise<boolean> {
    let promise:boolean = await new Promise((resolve, reject) => {
      if(this.dataStore.progress.masterclasses.hasOwnProperty(lid)) {
        const mc = Object.assign(this.dataStore.progress.masterclasses[lid], {"status":"Completed","end_date":new Date().toISOString()});
        const putdata = { 'course_id':cid, "masterclasses" : mc };
        this.http.put<ApiResponse>(this.baseUrl +'masterclasses', putdata)
        .toPromise()
        .then(result => {
          this.dataStore.progress.masterclasses[lid] = mc;
          this._progress.next(Object.assign({}, this.dataStore).progress);
          resolve(true);
        },
        msg => {
          // Error
          resolve(false);
        });
      }
      resolve(true);
    });
    return promise;
  }

  /* checked and commented : this method not in use */
  // async markActionReview(cid:number, step:any):Promise<boolean> {
  //   let promise:boolean = await new Promise((resolve, reject) => {
  //     if(!this.dataStore.progress.nextsteps.hasOwnProperty(step.id)) {
  //       const postdata = { 'course_id':cid, "nextsteps" : step };
  //       this.http.post<ApiResponse>(this.baseUrl +'nextsteps', postdata)
  //       .toPromise()
  //       .then(result => {
  //         this.dataStore.progress.nextsteps[step.id] = step;
  //         this._progress.next(Object.assign({}, this.dataStore).progress);
  //         resolve(this.dataStore.progress);
  //       },
  //       msg => {
  //         // Error
  //         reject('Could not save activity');
  //       });
  //     }
  //   });
  //   return promise;
  // }

  /* checked and commented : this method not in use */
  // async markActionComplete(cid:number, step:any):Promise<boolean> {
  //   let promise:boolean = await new Promise((resolve, reject) => {
  //     if(this.dataStore.progress.nextsteps.hasOwnProperty(step.id)) {
  //       const putdata = { 'course_id':cid, "nextsteps" : step };
  //       this.http.put<ApiResponse>(this.baseUrl +'nextsteps', putdata)
  //       .toPromise()
  //       .then(result => {
  //         this.dataStore.progress.nextsteps[step.id] = step;
  //         this._progress.next(Object.assign({}, this.dataStore).progress);
  //         resolve(this.dataStore.progress);
  //       },
  //       msg => {
  //         // Error
  //         reject('Could not save activity');
  //       });
  //     }
  //   });
  //   return promise;
  // }

}
