import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { of as observableOf,  Observable,  BehaviorSubject } from 'rxjs';
import { Event, EventData } from '../data/events';
import { ApiResponse } from 'app/@core/data/api-response';
import * as moment from 'moment';

@Injectable()
export class EventService extends EventData {

  baseUrl: string = environment.baseUrl + 'courses/event/';

  private _events = new BehaviorSubject<Event[]>([]);
  private dataStore: { events: Event[] } = { events: [] };
  readonly events = this._events.asObservable();

  constructor(private http: HttpClient) {super()}

  loadAll(course_id: number) :void
  {
    // return this.http.get<ApiResponse>(this.baseUrl + 'all');
    this.http.get<ApiResponse>(this.baseUrl + course_id + '/all').subscribe({
      next: (result:ApiResponse) => {
        if(result.status == 'ok') {
          this.dataStore.events = result.data;
          this._events.next(Object.assign({}, this.dataStore).events);
        }
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  load(id: number) :void
  {
    this.http.get<ApiResponse>(this.baseUrl + id).subscribe({
      next: (result:ApiResponse) => this.updateEventStore(result),
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  private updateEventStore(result:ApiResponse) {
    if(result.status == 'ok') {
      let notFound = true;
      let data = result.data;
      this.dataStore.events.forEach((item, index) => {
        if (item.id === data.id) {
          this.dataStore.events[index] = data;
          notFound = false;
        }
      });

      if (notFound) {
        this.dataStore.events.push(data);
      }

      this._events.next(Object.assign({}, this.dataStore).events);
    }
  }

  unload() :void
  {
    this.dataStore.events = [];
    this._events.next(Object.assign({}, this.dataStore).events);
  }

  getEvents(limit:number): Observable<Event[]>
  {
    return this._events;
  }

  getEvent(id: number): Observable<Event> {
    const events = this.dataStore.events.filter(event => event.id === id);
    return observableOf(events[0]);
  }

  getUpcomingEvents(): Observable<Event[]> {
    const events = this.dataStore.events.filter(event => moment(event.datetime).isAfter(moment()));
    return observableOf(events);
  }

  getPastEvents(): Observable<Event[]> {
    const events = this.dataStore.events.filter(event => moment(event.datetime).isBefore(moment()));
    return observableOf(events);
  }

  getModuleEvents(module_id:number): Observable<Event[]> {
    const events = this.dataStore.events.filter(event => event.module_id === module_id);
    return observableOf(events);
  }

}
