import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { of as observableOf,  BehaviorSubject, Observable } from 'rxjs';
import { Activity, ActivityData } from '../data/activity';
import { ApiResponse } from 'app/@core/data/api-response';

@Injectable()
export class ActivityService extends ActivityData {

  baseUrl: string = environment.baseUrl + 'courses/activity';
  hsUrl: string = environment.baseUrl + 'hs/';

  private _activity = new BehaviorSubject<Activity[]>([]);
  private dataStore: { activity: Activity[] } = { activity: [] };
  readonly activity = this._activity.asObservable();

  constructor(private http: HttpClient) {super()}

  loadAll() :void
  {
    this.http.get<ApiResponse>(this.baseUrl).subscribe({
      next: (result:ApiResponse) => {
        this.dataStore.activity = result.data;
        this._activity.next(Object.assign({}, this.dataStore).activity);
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  loadNext(page:number, limit:number) :Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(this.baseUrl + '?page='+page+'&limit='+limit);
  }

  load(course_id: number) :void
  {
    this.http.get<ApiResponse>(this.baseUrl + "/" + course_id).subscribe({
      next : (result:ApiResponse) => {
        let notFound = true;
        let data = result.data;
        this.dataStore.activity.forEach((item, index) => {
          if (item.id === data.id) {
            this.dataStore.activity[index] = data;
            notFound = false;
          }
        });
        if (notFound) {
          this.dataStore.activity.push(data);
        }
        this._activity.next(Object.assign({}, this.dataStore).activity);
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  unload() :void
  {
    this.dataStore.activity = [];
    this._activity.next(Object.assign({}, this.dataStore).activity);
  }

  getHSContact(email:string):Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.hsUrl + 'contact/' +email)
  }

  async save(activity:Activity) :Promise<ApiResponse>
  {
    let promise:ApiResponse = await new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.baseUrl, activity)
      .toPromise()
      .then(
        (res:ApiResponse) => resolve(res),
        msg => reject({msg:'Could not save activity', status:'error'})
      );
    });
    return promise;
  }

 async saveForm(formid:string, fields:any) :Promise<ApiResponse>
  {
    var hs_context = {hutk: '', pageUrl:'https://my.mirasee.com/course/smc/welcome/assesment-work', pageName:"Reflect on your Course Selling Strategy"};
    var data = {fields:fields, hs_context:hs_context};
    let promise:ApiResponse = await new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.hsUrl + 'form/' +formid, data)
      .toPromise()
      .then(
        (res:ApiResponse) => resolve(res),
        msg => {
          // Error
          reject({msd:'Could not save activity', status:'error'});
        }
      );
    });
    return promise;
  }

}
