import { Observable } from 'rxjs';
import { ApiResponse } from 'app/@core/data/api-response';

export interface EventLink {
  text: string;
  color: string;
}

export interface EventFilter {
  key :string;
  value:any;
  limit?:number;
}

export interface VidAction {
  icon: string;
  link: string;
  text: string;
  type: string;
}

export interface Event {
  id:number;
  course_id:number;
  module_id:number;
  type: string;
  name: string;
  topic: string;
  description: string;
  datetime:Date;
  enddate:string;
  timezone:string;
  options:any;
  status:string;
}

export abstract class EventData {
  abstract events:Observable<Event[]>;
  abstract loadAll(course_id: number):void;
  abstract load(id: number):void;
  abstract unload():void;
  abstract getEvents(limit:number): Observable<Event[]>;
  abstract getEvent(id: number): Observable<Event>;
  abstract getUpcomingEvents(): Observable<Event[]>;
  abstract getPastEvents(): Observable<Event[]>;
  abstract getModuleEvents(module_id:number): Observable<Event[]>;
}
