import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Activity, ActivityData } from 'app/@core/data/activity';
import { NbToastrService } from '@nebular/theme';
import { ApiResponse } from 'app/@core/data/api-response';
import { User } from 'app/@core/data/users';

@Component({
  selector: 'ngx-qa',
  templateUrl: 'qa.component.html',
  styleUrls: ['qa.component.scss'],
})

export class QAComponent implements OnInit {
  user: User;
  modalForm: FormGroup;
  submitted = false;

  @Input() title: string;
  @Input() label: string;
  @Input() subject: string;
  @Input() activity: any;

  constructor(
    private formBuilder: FormBuilder,
    protected dialogRef: NbDialogRef<QAComponent>,
    private toastrService: NbToastrService,
    private activityService: ActivityData,
  ) {
  }

  ngOnInit() {
    this.modalForm = this.formBuilder.group({
      content: ['', [Validators.required, Validators.maxLength(1000)]],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.modalForm.controls; }

  onFormSubmit() {
    if (this.modalForm.valid) {
      this.submitted = true;
      this.activity.content = this.modalForm.get('content').value;
      this.activityService.save(this.activity).then( (result:ApiResponse) => {
        this.submitted = false;
        this.toastrService.show(result.msg, "We'll get back to you within 1 business day :-)", { status: 'success'});
        this.dialogRef.close();
      });
    }
  }

  dismiss() {
    this.dialogRef.close();
  }
}
