<form [formGroup]="modalForm" (ngSubmit)="onFormSubmit()">
  <nb-card class="form-input-card">
    <nb-card-header class="py-3">{{ title }}</nb-card-header>
    <nb-card-body class="pt-0">
      <div class="mb-4">
        <label for="content" class="h6 text-uppercase">{{ label }}</label>
        <textarea #content nbInput size="large" rows="6" id="content" class="form-control mw-100" maxlength="1000" required="true" placeholder="Type your question" formControlName="content"
        [ngClass]="{ 'is-invalid': f.content.touched && f.content.errors }"></textarea>
        <div class="count-text text-sm text-muted my-2">{{f.content.value.length}} out of 1000 characters</div>
        <div *ngIf="f.content.touched && f.content.errors" class="invalid-feedback">
          <div *ngIf="f.content.errors.required">This is required field</div>
          <div *ngIf="f.content.errors.maxlength">Text can not be more then 1000 characters</div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button nbButton class="me-2" status="primary" [disabled]="!f.content.valid" [nbSpinner]="submitted" nbSpinnerStatus="info" nbSpinnerSize="large" nbSpinnerMessage="">Submit</button>
      <button nbButton (click)="dismiss()" outline>Cancel</button>
    </nb-card-footer>
  </nb-card>
</form>
