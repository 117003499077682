import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { of as observableOf,  Observable,  BehaviorSubject } from 'rxjs';
import { Library, Category, Newsfeed, LibraryData } from 'app/@core/data/library';
import { ApiResponse } from 'app/@core/data/api-response';

@Injectable()
export class LibraryService extends LibraryData {
  baseUrl: string = environment.baseUrl + 'courses/tyg-pro';

  private _library = new BehaviorSubject<Library[]>([]);
  private libraryStore: { library: Library[] } = { library: [] };
  readonly library = this._library.asObservable();

  private _category = new BehaviorSubject<Category[]>([]);
  private categoryStore: { category: Category[] } = { category: [] };
  readonly category = this._category.asObservable();

  private _newsfeed = new BehaviorSubject<Newsfeed[]>([]);
  private newsFeedStore: { newsfeed: Newsfeed[] } = { newsfeed: [] };
  readonly newsfeed = this._newsfeed.asObservable();

  constructor(private http: HttpClient) {super()}

  load() {
    this.getLibrary();
    this.getCategory();
    this.getNewsFeed();
  }

  getLibrary() :void
  {
    if(this.libraryStore.library.length > 0) {
      this._library.next(Object.assign({}, this.libraryStore).library);
    } else {
      this.http.get<ApiResponse>(this.baseUrl + "/library").subscribe({
        next: (result:ApiResponse) => {
          if(result.status == 'ok') {
            let data = result.data;
            this.libraryStore.library = data;
            this._library.next(Object.assign({}, this.libraryStore).library);
          }
        },
        error : (e) => console.log(e),
        complete: () => console.log('complete')
      });
    }
  }

  getCategory() :void
  {
    if(this.categoryStore.category.length > 0) {
      this._category.next(Object.assign({}, this.categoryStore).category);
    } else {
      this.http.get<ApiResponse>(this.baseUrl + "/category").subscribe({
        next: (result:ApiResponse) => {
          if(result.status == 'ok') {
            let data = this.nestData(result.data, 0);
            this.categoryStore.category = data;
            this._category.next(Object.assign({}, this.categoryStore).category);
          }
        },
        error : (e) => console.log(e),
        complete: () => console.log('complete')
      });
    }
  }

  getNewsFeed() :void
  {
    if(this.newsFeedStore.newsfeed.length > 0) {
      this._newsfeed.next(Object.assign({}, this.newsFeedStore).newsfeed);
    } else {
      this.http.get<ApiResponse>(this.baseUrl + "/newsfeed").subscribe({
        next: (result:ApiResponse) => {
          if(result.status == 'ok') {
            let data = result.data;
            this.newsFeedStore.newsfeed = data;
            this._newsfeed.next(Object.assign({}, this.newsFeedStore).newsfeed);
          }
        },
        error : (e) => console.log(e),
        complete: () => console.log('complete')
      });
    }
  }

  getPost(slug:string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + '/library/'+slug);
  }

  nestData(items:Category[], parent = 0) :Category[]
  {
    var out = []
    for(var i in items) {
      if(items[i].parent == parent) {
        var children = this.nestData(items, items[i].id)

        if(children.length) {
          items[i].children = children
        }
        out.push(items[i])
      }
    }
    return out
  }

  unload() :void
  {
    this.libraryStore.library = [];
    this.categoryStore.category = [];
    this.newsFeedStore.newsfeed = [];
    this._library.next(Object.assign({}, this.libraryStore).library);
    this._category.next(Object.assign({}, this.categoryStore).category);
    this._newsfeed.next(Object.assign({}, this.newsFeedStore).newsfeed);
  }

}
