import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { of as observableOf,  Observable,  BehaviorSubject } from 'rxjs';
import { ApiResponse } from 'app/@core/data/api-response';
import { User, Industry, UserData } from '../data/users';

@Injectable()
export class UserService extends UserData {

  baseUrl: string = environment.baseUrl + 'courses/';
  defaultValue = {'role': '', 'firstname': '', 'lastname': '', 'email': '', 'company': '', 'street': '','city':'','state':'','zip':'','country':'','timezone':'','avatar':'','bio':'', 'in_directory':1 ,'website':'','industry_id':0,'last_login':'','token':'', 'mist': {'credits':0, 'updated':new Date()}};

  private _user = new BehaviorSubject<User>(this.defaultValue);
  private dataStore: { user: User } = { user : this.defaultValue };
  readonly user = this._user.asObservable();
  isAdmin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {super()}

  load() :void
  {
    if(this.dataStore.user.email != '') {
      this._user.next(Object.assign({}, this.dataStore).user);
    } else {
      this.http.get<ApiResponse>(this.baseUrl + "auth/profile").subscribe({
        next: (result:ApiResponse) => {
          if(result.status == 'ok') {
            this.dataStore.user = result.data;
            var role = result.data.role == 'admin' || result.data.role == 'coach' ?  true : false;
            this.isAdmin.next(role);
            this._user.next(Object.assign({}, this.dataStore).user);
          }
        },
        error : (e) => console.log(e),
        complete: () => console.log('complete')
      });
    }
  }

  unload() :void
  {
    this.isAdmin.next(false);
    this.dataStore.user = this.defaultValue;
  }

  getIndustries() :Industry[]
  {
    let industries = [
      {id:1,name:'Art & Creativity', slug:'art-and-creativity',icon:'paint-brush'},
      {id:2,name:'Business & Marketing', slug:'business-and-marketing',icon:'briefcase'},
      {id:3,name:'Coaching & Personal Development', slug:'coaching-and-personal-Development',icon:'leaf'},
      {id:4,name:'Health & Wellness', slug:'health-and-wellness',icon:'heartbeat'},
      {id:5,name:'Leadership', slug:'leadership',icon:'slideshare'},
      {id:6,name:'Parenting & Family', slug:'parenting-and-Family',icon:'group'},
      {id:7,name:'Relationships', slug:'relationships',icon:'handshake-o'},
      {id:8,name:'Spirituality', slug:'spirituality',icon:'hand-paper-o'}
    ];
    return industries;
  }

  getProfile(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(this.baseUrl + "auth/profile");
  }

  saveProfile(user:any): Observable<ApiResponse>
  {
    return this.http.post<ApiResponse>(this.baseUrl + "auth/profile", user);
  }

  updateProfile(user:User) :void
  {
    this.dataStore.user = user;
    this._user.next(Object.assign({}, this.dataStore).user);
  }

  uploadAvatar(user:any): Observable<ApiResponse>
  {
    return this.http.post<ApiResponse>(this.baseUrl + "auth/avatar", user);
  }

  getUserMeta(id:number): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(this.baseUrl + "user/meta/"+id);
  }

  saveUserMeta(meta:any): Observable<ApiResponse>
  {
    return this.http.post<ApiResponse>(this.baseUrl + "user/meta", meta);
  }

  getUserBilling(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(this.baseUrl + "user/billing");
  }

  sendInvoice(order_id:string): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(this.baseUrl + "user/billing/invoice/"+order_id);
  }

}
