import { Component, OnDestroy, OnInit, Input} from '@angular/core';
import { Router } from "@angular/router";
import { takeUntil } from 'rxjs/operators' ;
import { Subject } from 'rxjs';
import { Form, FormData } from 'app/@core/data/form';
import { ApiResponse } from 'app/@core/data/api-response';
import { Course, Lesson } from 'app/@core/data/courses';

@Component({
  selector: 'ngx-peer-review-result',
  styleUrls: ['peer-review-result.component.scss'],
  templateUrl: './peer-review-result.component.html',
})

export class PeerReviewResultComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @Input() course:Course;
  @Input() lesson:Lesson;

  loading:boolean = true;
  errormsg:string = '';
  reviews:any = [];

  constructor(
    private router: Router,
    private formService: FormData,
  ) {}

  ngOnInit() {
    this.formService.getReviews(+this.lesson.content)
    .pipe(takeUntil(this.destroy$))
    .subscribe((result:ApiResponse)  => {
      if(result.status == 'error') {
        this.errormsg = result.msg;
      } else {
        this.reviews = result.data;
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
