import { Observable } from 'rxjs';
import { ApiResponse } from 'app/@core/data/api-response';

export interface DiscussionType {
  question:string;
  feedback:string;
  win:string;
  idea:string;
}

export interface Likes {
  id:number;
  comment_id:number;
  user_id:number;
  created_date:Date;
}

export interface Comment {
  id:number;
  discussion_id:number;
  user_id:number;
  comment: string;
  status:string;
  created_date:Date;
  likes: Likes;
}

export interface Discussion {
  id:number;
  course_id:number;
  module_id:number;
  lesson_id:number;
  type: string;
  topic: string;
  content: string;
  created_date:Date;
  status?:string;
  Comment?: Comment;
}

export interface Feed extends Discussion {
  user_id:number;
  course:string;
  cslug: string;
  lesson:string;
  lslug:string;
  module:string;
  mslug:string;
  user:string;
  avatar:string;
}


export abstract class DiscussionData {
  abstract discussions:Observable<Discussion[]>;
  abstract comments:Observable<Discussion[]>;
  abstract loadAll(course_id: number):void;
  abstract getLatestDiscussions(course_id: number):void;
  abstract loadLatestComments(course_id: number):void;
  abstract load(id: number):Observable<ApiResponse>;
  abstract unload():void;
  abstract loadNextComments(id: number, page:number): Observable<ApiResponse>;
  abstract getFeed(page:number, nocomment:boolean): Observable<ApiResponse>;
  // abstract save(postData:any);
  // abstract comment(postData:any);
  abstract like(postData:any): Observable<ApiResponse>;
  abstract getCurrentDiscussion():Discussion;
  abstract setCurrentDiscussion(discussion:Discussion):void;
  abstract getDiscussionsByModule(id:number): Observable<ApiResponse>;
  abstract getDiscussionsByLesson(id:number): Observable<ApiResponse>;
}
