export interface MistInfo {
  credits:number;
  updated:Date;
}

export interface OrderItem {
  item:string;
  description:string;
  cost:string
}

export interface NextOrder {
  original_order_id: string;
  item:string;
  description:string;
  total_cost:number;
  next_shipment_dts:Date;
  rebill_orders:number;
  status:string;
}

export interface Order {
  order_id: string;
  payment_method: string;
  payment_status: string;
  payment_dts: Date;
  total_cost: number;
  auto_order: boolean,
  items: OrderItem[]
}

export interface CourseSub {
  order_id:string;
  original_order_id:string;
  payment_method:string;
  payment_status:string;
  payment_dts:Date
  items:string;
  next_shipment_dts:Date|null;
  last_order_dts:Date|null;
  original_item_id:string|null;
  arbitrary_unit_cost:number|null;
  no_order_after_dts:string|null;
}

export interface Billing {
  'tyg-pro': CourseSub;
  aces?: CourseSub;
  orders: Order[];
  nextorders: NextOrder[];
}

export interface Student {
  id?:number;
  role: string;
  firstname: string;
  lastname: string;
  email: string;
  company:string;
  street?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  timezone: string;
  avatar: string;
  bio: string;
  website: string;
  industry_id:number;
  last_login:string;
  status:number;
  in_directory?:boolean|number;
  token?:string;
  mist?:MistInfo;
}

export interface CourseProgress {
  id: number;
  course_id:  number;
  name: string;
  type: null | string;
  status: any;
  Started: null | Date;
  Completed:  null | Date;
  entry_id: string | number | null,
  submitted: null | Date;
}

export interface StudentProgress {
  course:string;
  id:number;
  slug:string;
  progress: CourseProgress[];
}

export abstract class StudentsData {
  // abstract getStudents():Observable<ApiResponse> ;
}
