import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Assessment, FormData } from 'app/@core/data/form';
import { takeUntil } from 'rxjs/operators' ;
import { Subject } from 'rxjs';
import { ApiResponse } from 'app/@core/data/api-response';

@Component({
  selector: 'ngx-form-assessment',
  templateUrl: './assessment.component.html',
})
export class AssessmentComponent implements AfterViewInit, OnDestroy {
  @Input() formid:any;

  private destroy$: Subject<void> = new Subject<void>();

  assessments:string[] = [];
  loaded:boolean = false;

  constructor(
    private formService: FormData,
  ) { }

  ngAfterViewInit() {
    this.formService.getAssessment(+this.formid)
    .pipe(takeUntil(this.destroy$))
    .subscribe((result:ApiResponse) => {
      this.loaded = true;
      if(result.status == 'ok') {
        this.assessments = result.data.assessment;
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
