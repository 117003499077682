import { Directive, HostListener, Input } from '@angular/core';
import { MasterData } from 'app/@core/data/master';

@Directive({
  selector: '[ngExport]'
})
export class ngExportDirective {

  constructor(private masterService: MasterData) { }

  @Input('appExport') data: any[];
  @Input() fileName: string;

  @HostListener('click', ['$event']) onClick($event:Event) {
    this.masterService.exportData(this.data, this.fileName);
  }

}
