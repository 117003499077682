import { Directive, Input, Output, Renderer2, ElementRef, OnChanges, EventEmitter } from '@angular/core';
import { ScriptService } from 'app/@core/utils/script.service';

@Directive({
  selector: '[ngVideo]'
})

export class ngVideoDirective implements OnChanges{

  @Input('ngVideo') video:any;
  @Output() videoOutput? = new EventEmitter();

  renderHtml:any;
  autoplay:boolean;

  constructor(
    private el: ElementRef,
    private renderer :Renderer2,
    private scriptService:ScriptService
  ) {}

  ngOnChanges(){
    let video = this.video;
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', '');
    this.autoplay = localStorage.getItem('mrsautoplay') ? false : true;
    if(this.video.id){
      this.scriptService.load(this.video.type).then((data:any) => {
        this.bindHtml(this.video.type, data);
      }).catch(error => console.log(error));
    }
  }

  bindHtml(type:string, data:any):void {
    let content = "";
    if(type == 'wistia') {
      content = '<div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><div class="wistia_embed wistia_async_'+this.video.id+' seo=false videoFoam=true" style="height:100%;width:100%">&nbsp;</div></div></div>';
      data[0].window._wq = data[0].window._wq || [];
      data[0].window._wq.push({ id: this.video.id, onReady: (video:any) => {
        this.videoOutput.emit(video);
      }});
    } else if(type == 'vimeo') {
      content = '<div class="ratio ratio-16x9"><iframe src="https://player.vimeo.com/video/'+this.video.id+'?autoplay=1" width="100%" height="auto" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>';
      this.videoOutput.emit(data[0].window?.Vimeo);
      // content = '<div class="ratio ratio-16x9"><div data-vimeo-id="'+this.video.id+'" id="video_'+this.video.id+'" data-vimeo-autoplay="'+this.autoplay+'" data-vimeo-defer data-vimeo-width="500"></div></div>';
      // content+= "<script>var videoPlayer = new Vimeo.Player('video_"+this.video.id+"'); videoPlayer.play(); videoPlayer.on('play', function() {});</script>";
    } else if(type == 'youtube')  {
      content = '<div class="ratio ratio-16x9"><iframe width="100%" height="auto" src="https://www.youtube.com/embed/'+this.video.id+'" frameborder="0" allowfullscreen</iframe></div>';
    }
    this.renderHtml = content;
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.renderHtml);
  }

}
