import { Component, OnDestroy, OnInit, Input, Renderer2 } from '@angular/core';
import { Router } from "@angular/router";
import { Subject } from 'rxjs';
import { Course } from 'app/@core/data/courses';
import { EventFilter, EventLink } from 'app/@core/data/events';

@Component({
  selector: 'ngx-jumbotron',
  styleUrls: ['./jumbotron.component.scss'],
  templateUrl: './jumbotron.component.html',
})

export class JumbotronComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @Input() course: Course;
  @Input() iscommunity?:boolean = false;

  eventFilter:EventFilter = {key :'limit', value:1}
  eventLink:EventLink = {text:'View all group sessions & recordings', color:'text-white'}
  basepath:string;

  constructor(
    private router: Router,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit() {
    this.basepath = this.course.slug == 'aces' ? '/courses' : '/course';
    this.renderer.addClass(document.body, 'has-jumbotron');
  }

  getFilter(key:string, value:any, limit:number, truncate:number) {
    return {key :key, value:value, limit:limit, truncate:truncate}
  }

  goToFindPeep(course: Course):void {
    this.router.navigate(['course', course.slug, 'community', 'directory', 'peeps']);
  }

  goToDirectory(course: Course):void {
    this.router.navigate(['course', course.slug, 'directory']);
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'has-jumbotron');
    this.destroy$.next();
    this.destroy$.complete();
  }

}
