<nav id="mainHeader" class="navbar navbar-expand-lg navbar-light px-0 px-md-4 w-100 py-1 py-md-2 justify-content-between">
  <a class="navbar-brand ps-3 ps-md-0 logo" [routerLink]="['/course',course.slug]">
    <img [src]="course.options.logo" [alt]="course.name" height="50px">
  </a>
  <div class="bg-white mobile-menu">
      <nb-menu [items]="mainMenu" tag="mainmenu" class="mx-auto" [ngClass]="{'float-start inline-menu':!showMenu, 'position-fixed bottom-0 start-0':showMenu}"></nb-menu>
  </div>
  <nb-actions class="navbar-profile" size="small" *ngIf="user$ | async as user">
    <nb-action icon="bell-outline" badgeStatus="info" badgePosition="top right" [link]="getLink(course.slug)" class="badge-mobile"></nb-action>
    <nb-action class="user-action pe-4 pe-md-0">
      <nb-user [nbContextMenu]="userMenu" nbContextMenuTag='profile-menu' [name]="user.firstname" [picture]="user.avatar" [onlyPicture]="true"></nb-user>
    </nb-action>
  </nb-actions>
</nav>
