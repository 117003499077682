import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { of as observableOf,  BehaviorSubject } from 'rxjs';
import { Library, Category, ResourceLibraryData } from 'app/@core/data/resource-library';
import { ApiResponse } from 'app/@core/data/api-response';
import { Observable } from 'rxjs-compat';

@Injectable()
export class ResourceLibraryService extends ResourceLibraryData {
  baseUrl: string = environment.baseUrl + 'courses/aces';

  private _library = new BehaviorSubject<Library[]>([]);
  private libraryStore: { library: Library[] } = { library: [] };
  readonly library = this._library.asObservable();

  private _category = new BehaviorSubject<Category[]>([]);
  private categoryStore: { category: Category[] } = { category: [] };
  readonly category = this._category.asObservable();

  private _buzzcategory = new BehaviorSubject<Category[]>([]);
  private buzzcategoryStore: { category: Category[] } = { category: [] };
  readonly buzzcategory = this._buzzcategory.asObservable();

  constructor(private http: HttpClient) {super()}

  load() :void
  {
    this.getLibrary();
    this.getCategory();
    this.getBuzzCategory();
  }

  getLibrary() {
    if(this.libraryStore.library.length > 0) {
      this._library.next(Object.assign({}, this.libraryStore).library);
    } else {
      this.http.get<ApiResponse>(this.baseUrl + "/library").subscribe({
        next: (result:ApiResponse) => {
          if(result.status == 'ok') {
            let data = result.data;
            this.libraryStore.library = data;
            this._library.next(Object.assign({}, this.libraryStore).library);
          }
        },
        error : (e) => console.log(e),
        complete: () => console.log('complete')
      });
    }
  }

  getCategory() :void
  {
    if(this.categoryStore.category.length > 0) {
      this._category.next(Object.assign({}, this.categoryStore).category);
    } else {
      this.http.get<ApiResponse>(this.baseUrl + "/category").subscribe({
        next: (result:ApiResponse) => {
          if(result.status == 'ok') {
            let data = this.nestData(result.data, 0);
            this.categoryStore.category = data;
            this._category.next(Object.assign({}, this.categoryStore).category);
          }
        },
        error : (e) => console.log(e),
        complete: () => console.log('complete')
      });
    }
  }

  getBuzzCategory() :void
  {
    if(this.buzzcategoryStore.category.length > 0) {
      this._buzzcategory.next(Object.assign({}, this.buzzcategoryStore).category);
    } else {
      this.http.get<ApiResponse>(this.baseUrl + "/category/buzz-call").subscribe({
        next: (result:ApiResponse) => {
          if(result.status == 'ok') {
            let data = this.nestData(result.data, 0);
            this.buzzcategoryStore.category = data;
            this._buzzcategory.next(Object.assign({}, this.buzzcategoryStore).category);
          }
        },
        error : (e) => console.log(e),
        complete: () => console.log('complete')
      });
    }
  }

  getPost(slug:string): Observable<ApiResponse>
   {
    return this.http.get<ApiResponse>(this.baseUrl + '/library/'+slug);
  }

  nestData(items:Category[], parent:number = 0) :Category[]
  {
    var out = []
    for(var i in items) {
      if(items[i].parent == parent) {
        var children = this.nestData(items, items[i].id)

        if(children.length) {
          items[i].children = children
        }
        out.push(items[i])
      }
    }
    return out
  }

  unload() :void
  {
    this.libraryStore.library = [];
    this.categoryStore.category = [];
    this._library.next(Object.assign({}, this.libraryStore).library);
    this._category.next(Object.assign({}, this.categoryStore).category);
  }

}
