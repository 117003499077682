import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { NbAuthService } from '@nebular/auth';
import { User, UserData } from 'app/@core/data/users';
import { NbMenuItem } from '@nebular/theme';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'ngx-burger-menu',
  styleUrls: ['./burger-menu.component.scss'],
  templateUrl: './burger-menu.component.html',
})

export class BurgerMenuComponent implements OnInit, OnDestroy{

  user$:Observable<User>;
  isAdmin:boolean = false;
  items: NbMenuItem[] = [
    { title: 'My Courses', icon: 'checkmark-square', link: '/courses/my-courses', home: true },
    { title: 'Help & Support', icon: 'question-mark-circle-outline', link: '/courses/support' },
  ];
  adminMenu: NbMenuItem[] = [{
    title: 'Admin',
    icon: 'settings',
    url: '../admin/courses',
  }]

  constructor(
    private router: Router,
    private authService: NbAuthService,
    private userService: UserData,
  ) {}

  ngOnInit() {
    this.user$ = this.userService.user.pipe(map((user:User) => {
      if(user.email != '') {
        this.isAdmin = user.role == 'admin' || user.role == 'coach' ? true : false;
     }
     return user;
    }));
  }

  logOut() {
    this.authService.logout('email').subscribe(() => {
      this.userService.unload();
      this.router.navigate(['auth/logout']);
    });
  }

  goToProfile() {
    this.router.navigate(['courses/my-profile']);
  }

  ngOnDestroy() {
  }

}
