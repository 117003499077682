import { Component, OnDestroy, OnInit, Input, OnChanges} from '@angular/core';
import { Router } from "@angular/router";
import { takeUntil, distinctUntilChanged } from 'rxjs/operators' ;
import { Subject } from 'rxjs';
import { Course, Module, Lesson, MasterclassLesson, CourseData } from 'app/@core/data/courses';
import { Progress, ProgresItem, ProgressData } from 'app/@core/data/progress';
import * as moment from 'moment-timezone';

@Component({
  selector: 'ngx-lesson-list',
  styleUrls: ['./lesson-list.component.scss'],
  templateUrl: './lesson-list.component.html',
})

export class LessonListComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$: Subject<void> = new Subject<void>();

  @Input() type:keyof Progress;
  @Input() course: Course;
  @Input() module: Module;
  @Input() link?:number = 0;
  @Input() icon?:boolean = true;
  @Input() nextup?:boolean = false;

  private zone:string = "America/New_York";

  loading:boolean = true;
  lessons:Lesson;
  progress:Progress;

  constructor(
    private router: Router,
    private coursesService: CourseData,
    private progressService: ProgressData
  ) {}

  ngOnInit() {
    this.progressService.progress
    .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe( (progress:Progress) => {
      this.loading = false;
      if(progress.hasOwnProperty(this.type)) {
        // if(this.type == 'masterclasses' && this.nextup) {
        //   this.module.lessons = this.setNextLesson(this.module, progress);
        // }
        this.progress = progress;
      }
    });
  }

  setNextLesson(module:Module, progress:Progress) {
    let lessons:Lesson[] = [];
    const mlessons = module.lessons;
    // const plessons = Object.keys(progress.masterclasses).pipe(map(key => progress.masterclasses[key]));
    let pmlessons:ProgresItem[] = [];
    mlessons.forEach((keys : any, vals :any) => {
      if (progress.masterclasses.hasOwnProperty(keys.id)) {
        pmlessons.push(progress.masterclasses[keys.id]);
      }
    })
    let nextup = pmlessons.filter((lesson:ProgresItem) => lesson.status == 'Started').sort((a, b) =>  moment(b.start_date).valueOf() - moment(a.start_date).valueOf()).slice(0, 1)[0];
    if(nextup) {
      lessons = module.lessons.filter((lesson:Lesson) => lesson.id == nextup.id);
    } else {
      lessons = module.lessons.slice(0, 1);
    }
    return lessons;
  }

  getLessons(module:Module): Lesson[]
  {
    let lessons = module.lessons;
    if(this.type == 'masterclasses' && this.nextup) {
      lessons = this.setNextLesson(module, this.progress);
    }
    return lessons;
  }

  ngOnChanges() {
    // let a = moment().utc();
    // let b = moment.tz(this.module.start_date, this.zone).utc();
    // if(b.isAfter(a)){
    //   this.link = false;
    // }
  }

  getLessonName(lesson:Lesson) :string
   {
    let name = '<h3 class="mb-1 ltitle">';
    if(lesson.options.tag.text) {
      name+= '<span class="badge rounded-pill text-bg-'+lesson.options.tag.color+' me-1 float-start">'+lesson.options.tag.text+'</span>';
    }
    name+=lesson.name+'</h3>';
    return name;
  }

  getArray(limit:number): number[]
  {
    return Array.from({length: limit}, (v, i) => i)
  }

  getIconStatus(id:number, is_dependent:number) :string
  {
    if(this.checkLessonProgress(id))
      return 'success'
    return this.checkLessonProgress(id, 'Started') ? 'info' : 'default';
  }

  getIcon(id:number, is_dependent:number) :string
  {
    return this.checkLessonProgress(id) ? 'checkmark-square-2' : 'square';
  }

  checkLessonProgress(id:number, status = "Completed") :boolean
  {
    if(this.progress && this.progress[this.type]) {
      if(this.progress[this.type].hasOwnProperty(id)) {
        return this.progress[this.type][id].status == status ? true : false;
      }
    }
    return false
  }

  goToLesson(course:string, module:Module, lesson:Lesson): void {
    if(this.link) {
      this.coursesService.setCurrentModule(module);
      this.coursesService.setCurrentLesson(lesson);
      if(this.type == 'masterclasses') {
        this.coursesService.setBreadcrumb({'course':this.course.name,'masterclass':module.name, 'lesson':lesson.name});
        this.router.navigate(['course', course, this.type, module.slug, lesson.slug], {state:{'course':this.course.name, 'module':module.name, 'lesson':lesson.name}});
      } else {
        this.coursesService.setBreadcrumb({'course':this.course.name,'module':module.name, 'lesson':lesson.name});
        this.coursesService.setBreadcrumb({'course':this.course.name,'module':module.name, 'lesson':lesson.name});  this.router.navigate([course == 'aces' ? 'courses': 'course', course, module.slug, lesson.slug], {state:{'course':this.course.name, 'module':module.name, 'lesson':lesson.name}});
      }
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
