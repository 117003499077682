<div *ngIf="loaded">
  <div *ngIf="assessments.length > 0">
    <div *ngFor="let asmnt of assessments">
      <div [innerHTML]="asmnt | sanitizeHtml:[]"></div>
    </div>
  </div>
  <div *ngIf="assessments.length == 0">
    <div class="alert alert-info">Please go to the previous page to complete the assessment so we can let you know which track will better serve you.</div>
  </div>
</div>
