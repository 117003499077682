<nb-card [size]="size" class="cardhover masterclass" (click)="goToModule(course, masterclass)" [ngClass]="masterclass.is_owned ? 'pointer' : ''">
  <nb-card-header class="border-bottom">
    <h3>
      <a *ngIf="link" class="pointer paragraph float-end" (click)="goToModule(course, masterclass)">View</a>
      <nb-icon icon="{{masterclass.is_pack == 1 ? 'user-add-outline' : 'funnel-outline'}}" pack="mirasee" class="me-2 float-start"></nb-icon>
      {{masterclass.name}}</h3>
    <p class="text-disabled paragraph-2 mt-1 mb-0" [line-truncation]="1" [innerHTML]="masterclass.description"></p>
    <div class="mt-3" *ngIf="type == 'my'">
      <h2 class="mb-2">{{progress | number:'1.0-0'}}%<span class="paragraph-2 text-disabled ps-2">complete</span></h2>
      <nb-progress-bar [value]="progress" size="tiny" status="info"></nb-progress-bar>
    </div>
  </nb-card-header>
  <nb-card-body>
    <p>
      <nb-icon icon="user-outline" pack="mirasee" class="me-2 float-start"></nb-icon>
      <span class="h6 text-uppercase text-disabled">masterclass by</span>
    </p>
    <div class="">
      <nb-user class="float-end" name="" [picture]="masterclass.expert.avatar" title="" size="large" pictureOnly></nb-user>
      <h3 class="mb-1" [innerHTML]="masterclass.expert.name"></h3>
      <p class="paragraph-2 text-disabled">Guest Expert</p>
    </div>
    <button  *ngIf="type == 'available'" nbButton fullWidth size="large" status="primary" class="mt-3" (click)="previewMasterclass(course, masterclass)">Unlock Masterclass<nb-icon icon="arrow-ios-forward-outline" class="ms-2"></nb-icon></button>
    <p class="mt-4 mb-0" *ngIf="type == 'my'">
      <nb-icon icon="clock-outline" pack="mirasee" class="me-2 float-start"></nb-icon>
      <span class="h6 text-uppercase text-disabled">Next Up</span>
    </p>
    <ngx-lesson-list *ngIf="type == 'my'" type="masterclasses" [nextup]="true" [icon]="false" [course]="course" [module]="masterclass" [link]='masterclass.is_owned'></ngx-lesson-list>
  </nb-card-body>
</nb-card>
