import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActivityService } from './activity.service';
import { CoursesService } from './course.service';
import { EventService } from './event.service';
import { ProgressService } from './progress.service';
import { UserService } from './users.service';

const SERVICES = [
  ActivityService,
  CoursesService,
  EventService,
  ProgressService,
  UserService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [...SERVICES],
})

export class MockDataModule {
  static forRoot(): ModuleWithProviders<MockDataModule> {
    return {
      ngModule: MockDataModule,
      providers: [
        ...SERVICES,
      ],
    };
  }
}
