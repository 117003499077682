import { Directive, OnInit, Input, Renderer2, ElementRef } from '@angular/core';
import { ScriptService } from 'app/@core/utils/script.service';

declare global {
  interface Window { MyNamespace: any; }
}

@Directive({
  selector: '[ngHsForm]'
})

export class ngHsformDirective implements OnInit{

  @Input('ngHsForm') hsform:any;
  @Input('target') target:string;

  renderHtml:HTMLElement;
  // hbspt = window['hbspt'] || {};

  constructor(
    private el: ElementRef,
    private renderer :Renderer2,
    private scriptService:ScriptService
  ) {}

  ngOnInit():void
  {
    this.scriptService.loadScript('hsform').then((data:any) => {
      if(data.hasOwnProperty('status')) {
        this.bindHtml(data);
      }
    }).catch(error => console.log(error));
  }

  bindHtml(data:any): void
  {
    // let content = '<div id="'+this.target+'"></div><script>hbspt.forms.create({portalId:"415704",formId:"'+this.hsform+'",target:"#'+ this.target+'"});</script>';
    // let content:any = '<div id="'+this.target+'"></div>';
    this.renderHtml = this.renderer.createElement('div');
    this.renderer.setAttribute(this.renderHtml, 'id', this.target);
    this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.renderHtml);
    if(data.window) {
      data.window.hbspt.forms.create({
        portalId:"415704",
        formId:this.hsform,
        target: '#'+ this.target
      })
    }
  }
}
