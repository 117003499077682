import { Observable } from 'rxjs';

export class Feed {
  constructor(
    public title: string,
    public content: string,
    public createdAt: Date
  ) {
    this.title = title;
    this.content = content;
    this.createdAt = createdAt;
  }
}

export interface Notification {
  id:number;
  course_id:number;
  lesson_id:number;
  title?:string;
  module_id:number;
  topic:string;
  type: string;
  comment: string;
  body?:string;
  avatar: string;
  user:string;
  is_viewed:number;
  status:string;
  created_date:Date;
  ref_id:any;
}

export abstract class NotificationData {
  abstract notifications$:Observable<Notification[]>;
  abstract unread$:Observable<number>;
  abstract currentMessage:Observable<null>;
  abstract load(course_id:number):void;
  abstract getData(course_id:number):void;
  abstract markReadAll(course_id:number):void;
  abstract markRead(course_id:number, id:number):void;
  abstract requestPermission():void;
  abstract receiveMessage():void;
}
