import { AbstractControl, ValidationErrors } from '@angular/forms';

export class NgxValidator {
  static noSpaceValidator(control: AbstractControl) : ValidationErrors | null {
    if(control.value) {
      if((control.value as string).indexOf(' ') >= 0){
        return {noSpace: true}
      }
    }
    return null;
  }
}
