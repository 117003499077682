import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'ngx-placeholder',
  styleUrls: ['placeholder.component.scss'],
  templateUrl: 'placeholder.component.html',
})
export class PlaceholderComponent {
  @Input() type?: string;
  @Input() bg?: string = 'light';

  @HostBinding('attr.aria-label')
  label = 'Loading';
}
