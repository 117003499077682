import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { of as observableOf, Observable,  BehaviorSubject } from 'rxjs';
import { Discussion, DiscussionData } from '../data/discussion';
import { DirectoryData } from '../data/directory';
import { ApiResponse } from 'app/@core/data/api-response';

@Injectable()
export class DiscussionService extends DiscussionData {

  baseUrl: string = environment.baseUrl + 'courses/discussion';

  private _discussions = new BehaviorSubject<Discussion[]>([]);
  private discussionsStore: { discussions: Discussion[] } = { discussions: [] };
  readonly discussions = this._discussions.asObservable();

  private _comments = new BehaviorSubject<Discussion[]>([]);
  private commentsStore: { comments: Discussion[] } = { comments: [] };
  readonly comments = this._comments.asObservable();

  constructor(
    private http: HttpClient,
    private directoryService:DirectoryData,
  ) {super()}

  loadAll(course_id: number) :void
  {
    // return this.http.get<ApiResponse>(this.baseUrl + 'all');
    this.http.get<ApiResponse>(this.baseUrl + '/' + course_id + '/all').subscribe({
      next: (result:ApiResponse) => {
        if(result.status == 'ok') {
          this.discussionsStore.discussions = result.data;
          this._discussions.next(Object.assign({}, this.discussionsStore).discussions);
          this.loadLatestComments(course_id);
          this.directoryService.loadAll(course_id);
        }
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  loadLatestComments(course_id: number) :void
  {
    // return this.http.get<ApiResponse>(this.baseUrl + 'all');
    this.http.get<ApiResponse>(this.baseUrl + '/' + course_id + '/comments').subscribe({
      next: (result:ApiResponse) => {
        if(result.status == 'ok') {
          this.commentsStore.comments = result.data;
          this._comments.next(Object.assign({}, this.commentsStore).comments);
        }
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  load(id: number) :Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(this.baseUrl +'/'+id)
  }

  getLatestDiscussions(course_id:number) :void
  {
    this.http.get<ApiResponse>(this.baseUrl + '/' + course_id + '/latest').subscribe({
      next: (result:ApiResponse) => {
        if(result.status == 'ok' && result.data.length > 0) {
          this.discussionsStore.discussions = this.discussionsStore.discussions.concat(result.data);
          this._discussions.next(Object.assign({}, this.discussionsStore).discussions);
        }
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  unload() :void
  {
    this.discussionsStore.discussions = [];
    this.commentsStore.comments = [];
    this._discussions.next(Object.assign({}, this.discussionsStore).discussions);
    this._comments.next(Object.assign({}, this.commentsStore).comments);
  }

  loadNextComments(id: number, page:number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl +'/'+id +'/comments/page/'+page);
  }

  getFeed(page:number, nocomment:boolean): Observable<ApiResponse> {
    if(!nocomment) {
      return this.http.get<ApiResponse>(this.baseUrl +'/feed/'+page);
    } else {
      return this.http.get<ApiResponse>(this.baseUrl +'/feed/nocomment/'+page);
    }
  }

  /* checked and commented : method not in use */
  // async save(postData:any) :Promise<ApiResponse> {
  //   let promise:ApiResponse = await new Promise((resolve, reject) => {
  //     this.http.post<ApiResponse>(this.baseUrl, postData)
  //     .toPromise()
  //     .then(result => {
  //       if(result.status == 'ok') {
  //         this.discussionsStore.discussions.unshift(result.data);
  //         this._discussions.next(Object.assign({}, this.discussionsStore).discussions);
  //         resolve(result.data);
  //       } else {
  //         reject(false);
  //       }
  //     },
  //     error => {
  //       reject(false);
  //     });
  //   });
  //   return promise;
  // }

  /* checked and commented : method not in use */
  // comment(postData:any) {
  //   let promise = new Promise((resolve, reject) => {
  //     this.http.post<ApiResponse>(this.baseUrl + '/comment', postData)
  //     .toPromise()
  //     .then(result => {
  //       if(result.status == 'ok') {
  //         this.commentsStore.comments.unshift(result.data);
  //         this._comments.next(Object.assign({}, this.commentsStore).comments);
  //         resolve(result.data);
  //       } else {
  //         reject(false);
  //       }
  //     },
  //     error => {
  //       reject(false);
  //     });
  //   });
  //   return promise;
  // }

  like(postData:any): Observable<ApiResponse>  {
    return this.http.post<ApiResponse>(this.baseUrl + '/comment/like', postData);
  }

  getCurrentDiscussion():Discussion {
    return JSON.parse(localStorage.getItem('discussion'));
  }

  setCurrentDiscussion(discussion:Discussion) :void
  {
    localStorage.setItem('discussion', JSON.stringify(discussion));
  }

  getDiscussionsByModule(id:number): Observable<ApiResponse>  {
    return this.http.get<ApiResponse>(this.baseUrl + '/modules/'+id);
  }

  getDiscussionsByLesson(id:number): Observable<ApiResponse>  {
    return this.http.get<ApiResponse>(this.baseUrl + '/lessons/'+id);
  }

}
