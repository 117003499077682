import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse } from 'app/@core/data/api-response';
import { MistInfo } from './students';

export interface Industry {
  id:number;
  name:string;
  slug:string;
  icon:string;
}

export interface User {
  id?:number;
  role: string;
  firstname: string;
  lastname: string;
  email: string;
  company:string;
  street?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  timezone: string;
  avatar: string;
  bio: string;
  website: string;
  industry_id:number;
  last_login:string;
  in_directory?:boolean|number;
  token?:string|null;
  meta?:any;
  mist?:MistInfo;
}

export abstract class UserData {
  abstract user: Observable<User>;
  abstract isAdmin:BehaviorSubject<boolean>;
  abstract load():void;
  abstract unload():void;
  abstract getIndustries():Industry[];
  abstract getProfile(): Observable<ApiResponse>;
  abstract getUserMeta(id:number): Observable<ApiResponse>;
  abstract saveProfile(user:any): Observable<ApiResponse>;
  abstract getUserBilling(): Observable<ApiResponse>;
  abstract updateProfile(user:User): void;
  abstract saveUserMeta(meta:any): Observable<ApiResponse>;
  abstract uploadAvatar(user:any): Observable<ApiResponse>;
  abstract sendInvoice(order_id:string): Observable<ApiResponse>;
}
