import { Directive, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[ngActivity]'
})

export class ngActivityDirective {

  @Input('ngActivity') activity:any;
  @Input() msg:string;

  constructor() {}

  @HostListener('click', ['$event'])
  clickEvent(event:Event) :void
  {
    event.preventDefault();
    event.stopPropagation();
  }

}
