<div class="w-100 mb-0">
  <ng-content></ng-content>
  <nb-accordion multi class="nextsteps">
    <nb-accordion-item [collapsed]="collapsed" >
      <nb-accordion-item-body class="p-0" *ngIf="!loading">
        <nb-list *ngIf="nextsteps.length != 0">
          <nb-list-item class="border-0 px-0" *ngFor="let step of nextsteps.slice(0,3)">
            <nb-icon icon="square" class="float-start me-2"></nb-icon>
            <h3 class="mb-0">
              <a (click)="viewStep(course, step)" class="pointer {{color}}">{{step.name}}</a>
            </h3>
          </nb-list-item>
        </nb-list>
        <nb-list *ngIf="nextsteps.length == 0">
          <nb-list-item class="border-0 px-0" *ngIf="nextsteps">
            <p class="mb-0 {{color}}">Add next steps to your list by clicking on recommended action items throughout the program.</p>
          </nb-list-item>
        </nb-list>
      </nb-accordion-item-body>
    </nb-accordion-item>
  </nb-accordion>
</div>
