import { Observable } from 'rxjs';
import { ApiResponse } from 'app/@core/data/api-response';
import { Form } from './form';
import * as moment from 'moment';
import { AsyncValidatorFn, FormGroup } from '@angular/forms';

export interface QueryParams {
  [key: string]: string | number;
}

export interface ContentType {
  text:string;
  value:string;
}

export interface LessonVideo {
  id: string;
  type: string;
  title: string;
  duration: string;
  actions: Object;
}

export interface Options {
  logo: string;
  is_cohort: boolean;
  slack:string;
  actionplan:boolean;
  beacon:boolean|string;
  today?:string;
  mist?:string;
  directory?:boolean;
}

export interface MasterCourseOptions {
  logo: string;
  is_cohort: string;
  beacon:boolean|string;
}

export interface MasterCourse {
  id:number;
  name: string;
  slug: string;
  description: string;
  start_date: string;
  end_date: string;
  options: MasterCourseOptions;
  modules?: any;
  masterclasses?: any;
  status:number;
  uniqueid?:number;
  created_date:string;
}

export interface Course {
  id:number;
  name: string;
  slug: string;
  description: string;
  start_date: string;
  end_date: string;
  attendee:number;
  options: Options;
  modules: any;
  masterclasses: any;
  nextsteps?:any;
  course_enddate:Date;
  course_startdate:Date;
  course_status:number;
  progress:number;
}

export interface MasterModule {
  id:number;
  course_id:number;
  is_pack:number;
  slug: string;
  name: string;
  description: string;
  content:string;
  is_dependent:number;
  expert_id:number;
  start_date: string;
  end_date: string;
  options?: any;
  status:number;
  lessons?:MasterLesson[];
  created_date?:string;
}

export interface MasterLesson {
  id?:number;
  course_id:number;
  module_id:number;
  slug:string;
  type:string;
  name: string;
  description: string;
  content:string;
  is_dependent:number;
  order_by:number;
  start_date: string;
  end_date: string;
  options: any;
  status: number;
  created_date?:Date;
  formsubmitted?:any;
  progress?:any;
  completed?:any;
}

export abstract class MasterData {
  abstract baseUrl:string;
  abstract today:moment.Moment;
  abstract endday:moment.Moment;
  abstract defaultValue:MasterCourse;
  abstract courses: Observable<MasterCourse[]>;
  abstract course: Observable<MasterCourse>;
  abstract forms: Observable<Form[]>;
  abstract formatDate(datetime:moment.Moment,format?:string):string;
  abstract formatMoment(datetime:string):moment.Moment;
  abstract get(slug: string):Observable<ApiResponse>;
  abstract getByUrl(url: string):Observable<ApiResponse>;
  abstract post(slug: string, data:any):Observable<ApiResponse>;
  abstract put(slug: string, data:any):Observable<ApiResponse>;
  abstract delete(slug: string):Observable<ApiResponse>;
  abstract loadAll():void;
  abstract loadCourse(id:number):void;
  abstract unload():void;
  abstract loadForms():void;
  abstract updateCoursesStore(course:MasterCourse, action:string) :void;
  abstract updateCourseStore(course:MasterCourse, action:string) :void;
  abstract updateCourseModuleStore(module:MasterModule, action:string) :void;
  abstract updateCourseMasterclassStore(masterclass:MasterModule, action:string) :void;
  abstract updateCourseModuleLessonStore(lesson:MasterLesson, action:string) :void;
  // abstract updateFormStore(data, action);
  abstract slugValidator(table:string):AsyncValidatorFn;
  abstract courseForm():FormGroup;
  abstract moduleForm(cid:number, mid:number|string):FormGroup;
  abstract lessonForm(cid:number, mid:number, lid:number|string):FormGroup;
  abstract eventForm():FormGroup;
  abstract faqForm(cid:number, mid:number, lid:number):FormGroup;
  abstract studentForm():FormGroup;
  abstract grantCourseAccessForm(user_id:number):FormGroup;
  abstract libraryForm(cid:number, lid:number|string):FormGroup;
  abstract exportData(jsonData: any[], fileName: string):void;
}
