<div *ngIf="loaded">
  <div *ngIf="content.video">
    <h2>Your coach is {{content?.firstname}} {{content?.lastname}} </h2>
    <div class="my-4 mx-n4" *ngIf="content.video">
      <div [ngVideo]="video"></div>
    </div>
    <div [innerHTML]="content.text | sanitizeHtml:[]"></div>
  </div>
  <div *ngIf="!content.video">
    <div class="alert alert-info">Please go to the previous page to complete the form so we can let you know about your coach.</div>
  </div>
</div>
