import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[hrefToRouterLink]'
})
export class ngHrefToRouterLinkDirective implements OnInit, OnDestroy {
  private _listeners: { destroy: () => void }[] = [];

  constructor(
    private _router: Router,
    private _el: ElementRef,
    private _renderer: Renderer2
  ) {
  }

  ngOnInit() {
    // TODO how to guarantee this directive running after all other directives without setTimeout?
    setTimeout(() => {
      const links = this._el.nativeElement.querySelectorAll('a.fragment');
      links.forEach((link:any) => {
        // this._renderer.setAttribute(link, 'routerLink', link?.getAttribute('href'));
        // this._renderer.setAttribute(link, 'fragment', link?.getAttribute('fragment'));
        const destroyListener = this._renderer.listen(link, 'click',
        (_event) => {
          _event.preventDefault();
          _event.stopPropagation();
          const elmnt = this._el.nativeElement.querySelector(link?.getAttribute('href'));
          elmnt.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
          // this._router.navigate( ['.'], {fragment: link?.getAttribute('fragment')});
          // this._router.navigateByUrl(link?.getAttribute('fragment'));
        });
        this._listeners.push({ destroy: destroyListener });
      });
    },
    0);
  }

  ngOnDestroy(): void {
    this._listeners?.forEach(listener => listener.destroy());
    this._listeners = null;
  }

}
