
<div [class]="bg" [ngSwitch]="type">
  <div *ngSwitchCase="'course'">
    <nb-card size="small" class="cardhover">
      <nb-card-body>
        <div class="placeholder ph-img mb-3"></div>
        <div class="info">
          <h4 class="placeholder ph-h3 mb-3"></h4>
          <div class="placeholder ph-sm d-block"></div>
        </div>
      </nb-card-body>
      <nb-card-footer class="py-3">
        <p class="mb-0 placeholder ph-sm d-block"></p>
      </nb-card-footer>
    </nb-card>
  </div>
  <div *ngSwitchCase="'lesson'">
    <div class="d-flex w-100 pointer">
      <div class="d-flex-shrink">
        <nb-icon icon="square" class="align-self-center"></nb-icon>
      </div>
      <div class="flex-grow-1 ms-2">
        <h3 class="placeholder ph-h3 mb-1 d-block"><span class="badge rounded-pill text-bg-basic me-1"></span></h3>
        <div class="placeholder ph-sm d-block mt-1 mb-0" class="mb-1"></div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'masterclass'">
    <div class="placeholder ph-sm d-block" class="mb-1"></div>
    <div class="placeholder ph-xsm d-block"></div>
    <div class="placeholder ph-link mb-0 mt-2 d-block"></div>
  </div>
  <div *ngSwitchCase="'event'">
    <div class="d-flex">
      <div class="flex-shrink-0">
        <nb-icon icon="calendar-outline" pack="mirasee" class="align-self-start"></nb-icon>
      </div>
      <div class="flex-grow-1 ms-2">
        <div class="placeholder ph-sm d-block" class="mb-1"></div>
        <div class="placeholder ph-xsm d-block"></div>
        <div class="placeholder ph-link mb-0 mt-2 d-block"></div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'discussion'">
    <div class="d-flex w-100 pointer">
      <div class="d-flex-shrink">
        <nb-user class="pointer" picture="" title="" onlyPicture></nb-user>
      </div>
      <div class="flex-grow-1 ms-2">
        <h3 class="placeholder ph-h3 mb-3 d-block"></h3>
        <div class="mb-0 placeholder ph-sm d-block"></div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'comment'">
    <div class="d-flex w-100 pointer">
      <div class="d-flex-shrink">
        <nb-user class="me-2 pointer" picture="" title="" onlyPicture></nb-user>
      </div>
      <div class="flex-grow-1 ms-2">
        <div class="mb-0 placeholder ph-sm"></div>
        <p class="my-2"><nb-icon icon="corner-down-right" class="float-start me-2"></nb-icon><span class="placeholder ph-sm"></span></p>
        <div class="mb-0 placeholder ph-xsm d-block"></div>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'peep'">
    <nb-user class="me-2 pointer" picture="" title="" onlyPicture></nb-user>
  </div>
  <div *ngSwitchCase="'peepcard'">
    <nb-card class="card-list box">
      <nb-card-header class="text-center">
        <nb-user class="img-peeps text-center mb-4 d-inline-block" picture="" title="" onlyPicture></nb-user>
        <h2 class="card-title placeholder ph-h3"></h2>
        <h6 class="card-subtitle mt-2 placeholder ph-sm d-block"></h6>
      </nb-card-header>
      <nb-card-body class="text-start border-top">
        <p class="card-text"><span class="mb-0 placeholder ph-sm d-inline-block"></span></p>
        <p class="card-text"><span class="mb-0 placeholder ph-sm d-inline-block"></span></p>
        <p class="card-text"><span class="mb-0 placeholder ph-sm d-inline-block"></span></p>
        <p class="card-text mb-0"><span class="mb-0 placeholder ph-sm d-block"></span></p>
      </nb-card-body>
    </nb-card>
  </div>
  <div *ngSwitchCase="'peeps'">
    <div class="d-flex w-100 mt-4 mb-2">
      <div class="d-flex-shrink">
        <nb-user class="img-peeps align-self-center" picture="" title="" onlyPicture></nb-user>
      </div>
      <div class="flex-grow-1 ms-2">
        <h4 class="placeholder ph-h3 mb-3 d-block"></h4>
        <p class="mb-1 text-disabled paragraph-2">
          <nb-icon icon="layer" pack="mirasee" status="info" class="me-2 mt-1 float-start"></nb-icon>
          <span class="mb-0 placeholder ph-sm d-block"></span>
        </p>
        <p class="mb-0 text-disabled paragraph-2">
          <nb-icon icon="briefcase-outline" pack="mirasee" status="info" class="me-2 mt-1 float-start"></nb-icon>
          <span class="mb-0 placeholder ph-sm d-block"></span>
        </p>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'feed'">
    <h3 class="placeholder ph-h3 d-block"></h3>
    <p class="card-text mb-0"><span class="mb-0 placeholder ph-link d-inline-block"></span></p>
    <div class="mb-0 placeholder ph-xsm timeline_footer"></div>
  </div>
  <div *ngSwitchDefault>
    <div class="placeholder ph-sm"></div>
    <div class="placeholder ph-sm"></div>
    <div class="placeholder ph-sm"></div>
  </div>
</div>
