import { Component } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
  <nb-layout>
    <nb-layout-header fixed>
      <ngx-header></ngx-header>
    </nb-layout-header>
    <nb-layout-column>
      <ng-content select="router-outlet"></ng-content>
    </nb-layout-column>
    <nb-layout-footer class="footer" fixed>
      <ngx-footer></ngx-footer>
    </nb-layout-footer>
  </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  items: NbMenuItem[] = [
    {
      title: 'My Courses',
      icon: 'checkmark-square',
      link: '/courses/my-courses',
      home: true,
    },
    {
      title: 'Help & Support',
      icon: 'question-mark-circle-outline',
      link: '/courses/support',
    }
  ];

}
