import { Observable } from 'rxjs';
import { ApiResponse } from 'app/@core/data/api-response';

export interface Faq {
  id:number;
  course_id:number;
  module_id:number;
  lesson_id: number;
  user_id: number;
  question: string;
  answer: string;
  is_public:number;
  status:number;
}

export abstract class FaqData {
  abstract faqs:Observable<Faq[]>;
  abstract loadAll(course_id: number):void;
  abstract load(id: number):void;
  abstract unload():void;
  abstract getLessonFaq(id: number): Observable<Faq[]>
}
