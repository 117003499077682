import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { of as observableOf,  Observable,  BehaviorSubject } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { ApiResponse } from 'app/@core/data/api-response';
import { Form, FormData } from '../data/form';

@Injectable()
export class FormService extends FormData {

  baseUrl: string = environment.baseUrl + 'courses/form/';

  private _form = new BehaviorSubject<Form[]>([]);
  private dataStore: { form:any } = { form:{} };
  readonly form = this._form.asObservable();

  constructor(private http: HttpClient) {super()}

  load(form_id:number): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(this.baseUrl + form_id);
  }

  loadReview(review:number, form_id:number): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(this.baseUrl + 'review/'+review+'/'+form_id);
  }

  getFormEntry(form_id:number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + "entry/" + form_id);
  }

  getAssessment(form_id:number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + "assessment/" + form_id);
  }

  getCoachContent(form_id:number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + "coach/" + form_id);
  }

  getReviews(form_id:number): Observable<ApiResponse>  {
    return this.http.get<ApiResponse>(this.baseUrl + 'reviews/'+form_id);
  }

  getFortune(entry_id:number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.baseUrl + 'fortune/'+entry_id);
  }

  async saveFormEntry(form_id:number, entry:any) :Promise<ApiResponse>
  {
    let promise:ApiResponse = await new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.baseUrl + "entry/" + form_id, entry).pipe(delay(1000))
      .toPromise()
      .then(
        res => resolve(res),
        msg => {
          // Error
          reject({msg:'Could not save form', status:'error'});
        }
      );
    });
    return promise;
  }

  async saveSlackFormEntry(channelid:string, form_id:number, entry:any) :Promise<ApiResponse>
  {
    let promise:ApiResponse = await new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.baseUrl + "entry/slack/"+channelid +"/"+ form_id, entry).pipe(delay(1000))
      .toPromise()
      .then(
        res => resolve(res),
        msg => {
          // Error
          reject({msg:'Could not save form', status:'error'});
        }
      );
    });
    return promise;
  }

  async saveReviewEntry(review:number, form_id:number, entry:any) :Promise<ApiResponse>
  {
    let promise:ApiResponse = await new Promise((resolve, reject) => {
      this.http.post<ApiResponse>(this.baseUrl + 'review/'+review+'/'+form_id, entry).pipe(delay(1000))
      .toPromise()
      .then(
        res => resolve(res),
        msg => {
          // Error
          reject({msg:'Could not save form', status:'error'});
        }
      );
    });
    return promise;
  }

  autoSaveFormEntry(form_id:number, fields:any) :Observable<ApiResponse>
  {
    return this.http.post<ApiResponse>(this.baseUrl + 'autosave/'+form_id, fields);
  }

  getFormTableEntry(form_id:number, field_id:number) :Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(this.baseUrl + "table/"+form_id+"/field/"+field_id);
  }

  saveFormTableEntry(form_id:number, entry:any) :Observable<ApiResponse>
  {
    return this.http.post<ApiResponse>(this.baseUrl + 'table/'+form_id, entry);
  }

  updateFormTableEntry(id:number, entry:any) :Observable<ApiResponse>
  {
    return this.http.put<ApiResponse>(this.baseUrl + 'table/'+id, entry);
  }

  updateFortune(segment:number) :Observable<ApiResponse>
  {
    return this.http.put<ApiResponse>(this.baseUrl + 'fortune/'+segment, {});
  }

}
