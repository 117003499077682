@defer (on viewport) {
<div *ngIf="peeps$ | async as peeps;">
  <div [ngSwitch]="style">
    <div class="w-100" *ngSwitchCase="'piconly'">
      <ng-container *ngTemplateOutlet="peepPiconly"></ng-container>
    </div>
    <div class="w-100" *ngSwitchCase="'card'">
      <ng-container *ngTemplateOutlet="peepCard"></ng-container>
    </div>
    <div class="w-100" *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="peepMiniCard"></ng-container>
    </div>
  </div>
</div>
} @placeholder (minimum 500ms) {
  <div>
    <ng-container *ngTemplateOutlet="loadingpeeps"></ng-container>
  </div>
}

<ng-template #loadingpeeps>
  <div [ngSwitch]="style">
    <div class="w-100" *ngSwitchCase="'piconly'">
      <div class="row">
        <div class="col-12 col-lg-{{col}}" *ngFor="let i of getArray(4)">
          <ngx-placeholder type="peeps" class="w-100"></ngx-placeholder>
        </div>
      </div>
    </div>
    <div class="row mt-4" *ngSwitchCase="'card'">
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 col-xxxl-2 pointer" *ngFor="let _ of [].constructor(filter.limit != -1 ? filter.limit : 24);">
          <nb-card class="card-list box">
            <nb-card-header class="text-center">
              <nb-user class="img-peeps align-self-center mb-4 justify-content-center" name="" [size]="'giant'" shape="round"></nb-user>
              <h2 class="card-title"><span class="placeholder placeholder-glow col-5"></span></h2>
              <h6 class="card-subtitle mt-2 text-muted"><span class="placeholder placeholder-sm placeholder-glow col-3"></span></h6>
            </nb-card-header>
            <nb-card-body class="text-start border-top">
              <div class="card-text">
                <nb-icon icon="email-outline" pack="mirasee" class="me-2 float-start d-inline-block text-muted"></nb-icon>
                <p><span class="placeholder placeholder-sm placeholder-glow col-5"></span></p>
              </div>
              <p class="card-text"><nb-icon icon="compass-outline" pack="mirasee" class="me-2 text-muted"></nb-icon><span class="placeholder placeholder-sm placeholder-glow col-5"></span></p>
              <p class="card-text"><nb-icon icon="briefcase-outline" pack="mirasee" class="me-2 text-muted"></nb-icon><span class="placeholder placeholder-sm placeholder-glow col-5"></span></p>
              <p class="card-text mb-0"><nb-icon icon="link-2-outline" class="me-2 text-muted"></nb-icon><span class="placeholder placeholder-sm placeholder-glow col-5"></span></p>
            </nb-card-body>
          </nb-card>
      </div>
    </div>
    <div class="row" *ngSwitchDefault>
      <div class="col-12 col-lg-{{col}} pointer box mt-4" *ngFor="let _ of [].constructor(filter.limit != -1 ? filter.limit : 27);">
        <div class="d-flex my-3 pointer">
          <div class="flex-shrink-0">
            <nb-user class="img-peeps align-self-center pointer" name="" [size]="'giant'" shape="round"></nb-user>
          </div>
          <div class="flex-grow-1 ms-3">
            <h3 class="mb-1 pointer"><span class="placeholder placeholder-sm placeholder-glow col-3"></span></h3>
            <p class="mb-1 text-disabled paragraph-2">
              <nb-icon icon="layer" pack="mirasee" status="info" class="me-2 mt-1 float-start"></nb-icon>
              <span class="placeholder placeholder-glow placeholder-sm col-3"></span>
            </p>
            <p class="mb-0 text-disabled paragraph-2">
              <nb-icon icon="briefcase-outline" pack="mirasee" status="info" class="me-2 mt-1 float-start"></nb-icon>
              <span class="placeholder placeholder-glow placeholder-sm col-4"></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #peepPiconly>
  <div *ngIf="(peeps$ | async | orderBy:['name'] | filterBy: ['name','email','company','industry','country']: searchField) as peeps;">
    <div class="card-accent" *ngFor="let p of peeps; trackBy trackByPeeps" (click)="goToProfile(p)">
      @defer (on viewport; on timer(5s)) {
        <nb-user class="mb-2 me-3 img-peeps pointer" name="" [picture]="p.avatar" size="giant" shape="round" onlyPicture [nbTooltip]="p.name"></nb-user>
      } @placeholder (minimum 500ms) {
        <nb-user class="mb-2 me-3 img-peeps pointer" [name]="p.name" title="" [size]="'giant'" shape="round" onlyPicture></nb-user>
      }
    </div>
    <div *ngIf="peeps.length == 0"> Oops! No matching peeps found</div>
  </div>
</ng-template>

<!-- <ng-template #noPeeps class="bg-white">
  <div class="col-12 mt-4">
    <nb-alert class="w-100 box mb-0" outline="info" status="basic">
      <p class="mb-0">Oops! No matching peeps found</p>
    </nb-alert>
  </div>
</ng-template> -->

<ng-template #peepMiniCard>
  <div *ngIf="(peeps$ | async | orderBy:['name'] | filterBy: ['name','email','company','industry','country']: searchField) as peeps;">
    <div class="row" *ngIf="peeps.length > 0; else noPeeps">
      <div class="col-12 col-lg-{{col}} pointer box mt-4" *ngFor="let p of peeps; trackBy trackByPeeps" (click)="goToProfile(p)" [nbPopover]="peepPopoverCard" [nbPopoverContext]="p" nbPopoverPlacement="top" nbPopoverAdjustment="counterclockwise" nbPopoverTrigger="hint" [nbPopoverOffset]="20">
        <div class="d-flex my-3 pointer">
          <div class="flex-shrink-0">
            @defer (on viewport) {
              <nb-user class="img-peeps align-self-center pointer" name="" [picture]="p.avatar" [size]="isSm ? 'giant':'medium'" shape="round" onlyPicture></nb-user>
            } @placeholder (minimum 500ms) {
              <nb-user class="img-peeps align-self-center pointer" [name]="p.name" title="" [size]="isSm ? 'giant':'medium'"  shape="round" onlyPicture></nb-user>
            }
          </div>
          <div class="flex-grow-1 ms-3">
            <h3 class="mb-1 pointer" [innerHTML]="p.name | highlight: search | ucfirst"></h3>
            <p class="mb-1 text-disabled paragraph-2" *ngIf="p.masterclasses">
              <nb-icon icon="layer" pack="mirasee" status="info" class="me-2 mt-1 float-start"></nb-icon>
              <span *ngIf="course.masterclasses.length == 0">{{course.name}} Participant</span>
              <span *ngIf="course.masterclasses.length != 0">
                <span *ngIf="p.masterclasses.length == 0">NA</span>
                <span *ngIf="p.masterclasses.length != 0">{{p.masterclasses | truncateobject:['2']}}</span>
              </span>
            </p>
            <p class="mb-0 text-disabled paragraph-2">
              <nb-icon icon="briefcase-outline" pack="mirasee" status="info" class="me-2 mt-1 float-start"></nb-icon>
              <span [innerHTML]="p.industry | highlight: search"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="showcount && count > 0">
        <p class="text-center mt-3 mb-0"><a (click)="goToPage(course, showcount)" class="pointer">+{{count}} more<nb-icon icon="chevron-down" class="ms-2"></nb-icon></a></p>
      </div>
    </div>
  </div>
  <ng-template #noPeeps class="col-12 mt-4">
    <nb-alert class="w-100 box mb-0" outline="info" status="basic">
      <p class="mb-0">Oops! No matching peeps found</p>
    </nb-alert>
  </ng-template>
</ng-template>

<ng-template #peepCard>
  <div *ngIf="(peeps$ | async | orderBy:['name'] | filterBy: ['name','email','company','industry','country']: searchField) as peeps;">
    <div class="row mt-4" *ngIf="peeps.length; else noPeeps">
      <div class="col-12 col-md-6 col-lg-4 col-xl-3 col-xxxl-2 pointer" *ngFor="let p of peeps; trackBy trackByPeeps" (click)="goToProfile(p)"  [nbPopover]="peepBioPopoverCard" [nbPopoverContext]="p" nbPopoverPlacement="top" nbPopoverAdjustment="counterclockwise" nbPopoverTrigger="hint" [nbPopoverOffset]="20">
        <nb-card class="card-list box">
          <nb-card-header class="text-center">
            @defer (on viewport; on timer(5s)) {
              <nb-user class="img-peeps align-self-center pointer mb-4 justify-content-center" name="" [picture]="p.avatar" [size]="isSm ? 'giant':'medium'" shape="round" onlyPicture></nb-user>
            } @placeholder (minimum 500ms) {
              <nb-user class="img-peeps align-self-center mb-4 justify-content-center" [name]="p.name" title="" [size]="'giant'" shape="round" onlyPicture></nb-user>
            }
            <h2 class="card-title text-capitalise" [innerHTML]="p.name | highlight: search" [line-truncation]="1"></h2>
            <h6 class="card-subtitle mt-2 text-muted" [line-truncation]="1" [watchChanges]="true" [innerHTML]="p.company ? p.company : 'na' | highlight: search"></h6>
          </nb-card-header>
          <nb-card-body class="text-start border-top">
            <div class="card-text">
              <nb-icon icon="email-outline" pack="mirasee" class="me-2 float-start d-inline-block text-muted"></nb-icon>
              <p [innerHTML]="p.email | highlight: search" [line-truncation]="1" [watchChanges]="true"></p>
            </div>
            <p class="card-text" [line-truncation]="1"><nb-icon icon="compass-outline" pack="mirasee" class="me-2 text-muted"></nb-icon><span [innerHTML]="getStateCountry(p) | highlight: search"></span></p>
            <p class="card-text" [line-truncation]="1"><nb-icon icon="briefcase-outline" pack="mirasee" class="me-2 text-muted"></nb-icon><span [innerHTML]="p.industry | highlight: search"></span></p>
            <p class="card-text mb-0" [line-truncation]="1"><nb-icon icon="link-2-outline" class="me-2 text-muted"></nb-icon>{{p.website ? p.website :'Unknown'}}</p>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
    <ng-template #noPeeps class="col-12 mt-4">
      <nb-alert class="w-100 box mb-0" outline="info" status="basic">
        <p class="mb-0">Oops! No matching peeps found</p>
      </nb-alert>
    </ng-template>
  </div>
</ng-template>

<ng-template #peepPopoverCard let-data>
  <nb-card class="mb-0 popovercard border-0" style="min-width:320px;max-width:420px">
    <nb-card-header>
      <nb-user class="me-1 img-peeps align-self-center pointer" [name]="data.name" [picture]="data.avatar" [title]="data.email" shape="round"></nb-user>
    </nb-card-header>
    <nb-card-body class="border-top">
      <p [innerHTML]="data.bio | sanitizeHtml:[]" [line-truncation]="3" class="text-xsm"></p>
      <p class="card-text"><nb-icon icon="compass-outline" pack="mirasee" class="me-2 text-paragraph"></nb-icon><span [innerHTML]="getStateCountry(data)"></span></p>
      <p class="card-text"><nb-icon icon="briefcase-outline" pack="mirasee" class="me-2 text-paragraph"></nb-icon>{{data.industry}}</p>
      <p class="card-text mb-0" [line-truncation]="1"><nb-icon icon="link-2-outline" class="me-2 text-paragraph"></nb-icon>{{data.website ? data.website : 'Unknown'}}</p>
    </nb-card-body>
  </nb-card>
</ng-template>

<ng-template #peepBioPopoverCard let-data>
  <nb-card class="mb-0 popovercard border-0" style="min-width:320px;max-width:420px">
    <nb-card-header>
      <nb-user class="me-1 img-peeps align-self-center pointer" [name]="data.name" [picture]="data.avatar" [title]="data.email" shape="round"></nb-user>
    </nb-card-header>
    <nb-card-body class="border-top">
      <p [innerHTML]="data.bio | sanitizeHtml:[]" [line-truncation]="3" class="text-xsm"></p>
    </nb-card-body>
  </nb-card>
</ng-template>
