import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Router } from "@angular/router";
import { takeUntil } from 'rxjs/operators' ;
import { Subject } from 'rxjs';
import { Course, Lesson, Module, NextStep, CourseData } from 'app/@core/data/courses';

@Component({
  selector: 'ngx-action-plan',
  styleUrls: ['./action-plan.component.scss'],
  templateUrl: './action-plan.component.html',
})

export class ActionPlanComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @Input() course: Course;
  @Input() color?: string = '';

  collapsed:boolean = false;
  lesson: Lesson;
  module: Module;
  nextsteps: NextStep[];
  loading:boolean = true;

  constructor(
    private router: Router,
    private coursesService: CourseData,
  ) {}

  ngOnInit() {
    this.coursesService.course
    .pipe(takeUntil(this.destroy$))
    .subscribe((course:Course) => {
      this.loading = false;
      if(course.hasOwnProperty('nextsteps')) {
        this.nextsteps = course.nextsteps.filter((nextstep:NextStep) => nextstep.end_date == '' || nextstep.end_date == null);
      }
    });
  }

  viewStep(course:Course, action:NextStep) {
    let modules = course.modules.concat(course.masterclasses);
    let module = modules.filter((module:Module) => module.id == action.module_id)[0];
    let lesson = module.lessons.filter((lesson:Lesson) => lesson.id == action.lesson_id)[0];
    this.coursesService.setCurrentModule(module);
    this.coursesService.setCurrentLesson(lesson);
    this.coursesService.setBreadcrumb({'course':course.name, 'module':module.name, 'lesson':lesson.name});
    this.router.navigate(['course', course.slug, module.slug, lesson.slug]);
  };

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
