import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject, BehaviorSubject} from "rxjs";
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiResponse } from 'app/@core/data/api-response';
import { Notification, NotificationData } from "app/@core/data/notification";

@Injectable()
export class NotificationService extends NotificationData {

  baseUrl: string = environment.baseUrl + 'courses/';
  randomGeneration: boolean;
  notifications$: Observable<Notification[]>;
  unread$: Observable<number>;

  private notificationsSubject: ReplaySubject<Notification[]>;
  private notifications = new Array<Notification>();
  private unreadSubject = new Subject<number>();

  currentMessage = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    // private angularFireMessaging: AngularFireMessaging
  ) {
    super();

    this.notificationsSubject = new ReplaySubject<Notification[]>(10);
    this.notifications$ = this.notificationsSubject.asObservable();
    this.unreadSubject.next(0);
    this.unread$ = this.unreadSubject.asObservable();
  }

  load(course_id:number):void
  {
  }

  getData(course_id:number):void
  {
    this.http.get<ApiResponse>(this.baseUrl + 'notification/'+course_id).subscribe({
      next: (result:ApiResponse) => {
        if(result.status == 'ok') {
          // this.notifications = this.notifications.concat(result.data);
          let unread = result.data.filter( (noti:Notification) => noti.is_viewed == 0)
          this.unreadSubject.next(unread.length);
          this.notificationsSubject.next(result.data);
        }
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  unload():void
  {
    this.notificationsSubject.next([]);
  }

  markReadAll(course_id:number):void
  {
    this.http.put<ApiResponse>(this.baseUrl + 'notification/'+course_id +'/markadread',{}).subscribe({
      next: (result:ApiResponse) => {
        if(result.status == 'ok') {
          this.getData(course_id);
        }
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  markRead(course_id:number, id:number):void
  {
    this.http.put<ApiResponse>(this.baseUrl + 'notification/'+course_id +'/'+id, {}).subscribe({
      next: (result:ApiResponse) => {
        if(result.status == 'ok') {
          this.getData(course_id);
        }
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  clearNotifications():void
  {
    this.notifications = new Array<Notification>();
    this.notificationsSubject.next(this.notifications);
  }

  requestPermission():void {
  }

  receiveMessage():void {
  }
}
