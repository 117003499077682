import { Component, OnDestroy, OnInit, Input} from '@angular/core';
import { Router } from "@angular/router";
import { delay, map } from 'rxjs/operators' ;
import { Observable } from 'rxjs';
import { Course } from 'app/@core/data/courses';
import { Event, EventLink, EventFilter, EventData } from 'app/@core/data/events';
import * as moment from 'moment-timezone';

@Component({
  selector: 'ngx-course-event',
  styleUrls: ['./event.component.scss'],
  templateUrl: './event.component.html',
})

export class EventComponent implements OnInit, OnDestroy {
  @Input() title: string;
  // @Input() events: Event[];
  @Input() course: Course;
  @Input() filter: EventFilter|null;
  @Input() outline?: any = 'basic';
  @Input() link?: EventLink;
  @Input() searchField?: string;

  events$: Observable<Event[]>;
  event: any;
  loading = true;
  basepath:string;

  constructor(
    private router: Router,
    private eventService: EventData,
  ) {}

  ngOnInit() {
    this.basepath = this.course.slug == 'aces' ? '/courses' : '/course';
    this.events$ = this.eventService.events
    .pipe(
      delay(100),
      map((events:Event[]) => {
          if(this.filter.key == 'limit') {
            return events.filter(event => moment(event.enddate).isAfter(moment())).slice(0,this.filter.value);
          } else if(this.filter.key == 'upcoming') {
            return events.filter(event => moment(event.datetime).isAfter(moment()));
          } else if(this.filter.key == 'past') {
            let pastevents = events.filter(event => moment(event.datetime).isBefore(moment()) && !event.options.video);
            return pastevents.sort((a, b) => moment(b.datetime).valueOf() - moment(a.datetime).valueOf());
          } else if(this.filter.key == 'pastrecording') {
            let pastevents = events.filter(event => this.filterPastRecording(event));
            return pastevents.sort((a, b) => moment(b.datetime).valueOf() - moment(a.datetime).valueOf());
          } else if(this.filter.key == 'module') {
            return events.filter(event => event.module_id === this.filter.value);
          } else {
            return events;
          }
      })
    )
  }

  filterPastRecording(event:Event) {
    if(typeof event.options.video === 'object' && event.options.video !== null)
      return moment(event.datetime).isBefore(moment()) && event.options.video.id != '';
    return false;
  }

  viewEvent(id:Number): void {
    if(![11,31].includes(this.course.id)) {
      this.router.navigate(['course', this.course.slug, 'events', id]);
    }
  };

  goToPage(course:string, slug:string):void {
    if(course == 'tyg-pro' || course == 'aces') {
      this.router.navigate(['courses', course, slug]);
    } else {
      this.router.navigate(['course',course, slug]);
    }
  }

  ngOnDestroy() {
  }
}
