import { ModuleWithProviders, NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbPasswordAuthStrategy, NbAuthModule, NbAuthJWTToken, NB_AUTH_TOKEN_INTERCEPTOR_FILTER} from '@nebular/auth';
import { environment } from 'environments/environment';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { MiraseeInterceptor, HttpErrorInterceptor, GlobalErrorHandler, ErrorLogService } from 'app/@core/http.interceptor';
import { AuthGuard } from 'app/@core/auth-guard.service';
import { WINDOW_PROVIDERS } from 'app/@core/windowref.provider';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AnalyticsService, LayoutService, ScriptService } from './utils';

import { ActivityData } from './data/activity';
import { CourseData } from './data/courses';
import { DirectoryData } from './data/directory';
import { DiscussionData } from './data/discussion';
import { EventData } from './data/events';
import { FaqData } from './data/faqs';
import { FormData } from './data/form';
import { LibraryData } from './data/library';
import { MasterData } from './data/master';
import { NotificationData } from './data/notification';
import { ProgressData } from './data/progress';
import { ResourceLibraryData } from './data/resource-library';
import { StudentsData } from './data/students';
import { UserData } from './data/users';

import { MockDataModule } from './mock/mock-data.module';
import { ActivityService } from './mock/activity.service';
import { CoursesService } from './mock/course.service';
import { DirectoryService } from './mock/directory.service';
import { DiscussionService } from './mock/discussion.service';
import { EventService } from './mock/event.service';
import { FaqService } from './mock/faq.service';
import { FormService } from './mock/form.service';
import { LibraryService } from './mock/library.service';
import { MasterService } from './mock/master.service';
import { NotificationService } from './mock/notification.service';
import { ProgressService } from './mock/progress.service';
import { ResourceLibraryService } from './mock/resource-library.service';
import { StudentsService } from './mock/students.service';
import { UserService } from './mock/users.service';

const DATA_SERVICES = [
  { provide: ActivityData, useClass : ActivityService},
  { provide: CourseData, useClass: CoursesService},
  { provide: DirectoryData, useClass: DirectoryService},
  { provide: DiscussionData, useClass: DiscussionService},
  { provide: EventData, useClass: EventService},
  { provide: FaqData, useClass: FaqService},
  { provide: FormData, useClass: FormService},
  { provide: LibraryData, useClass: LibraryService},
  { provide: NotificationData, useClass: NotificationService },
  { provide: ProgressData, useClass: ProgressService },
  { provide: ResourceLibraryData, useClass: ResourceLibraryService},
  { provide: MasterData, useClass: MasterService },
  { provide: StudentsData, useClass: StudentsService },
  { provide: UserData, useClass: UserService },
];

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: environment.baseUrl,
        login: {
          endpoint: 'courses/auth/signin',
          requireValidToken:true,
          redirect: {
            success: '/auth/success',
            failure: null,
          },
          defaultMessages: ['You have been successfully logged in.'],
        },
        logout:{
          alwaysFail: false,
          endpoint:'courses/auth/signout',
          requireValidToken:true,
        },
        register: false,
        requestPass: {
          endpoint: 'courses/auth/requestpass',
          method: 'post',
          redirect: {
            success: '/auth/request-pass',
            failure: null,
          },
          defaultMessages: ['Reset password instructions have been sent to your email.'],
        },
        resetPass: {
          endpoint: 'courses/auth/resetpass',
          method: 'put',
          redirect: {
            success: '/auth/login',
            failure: null,
          },
          resetPasswordTokenKey: 'resettoken',
          defaultMessages: ['Your password has been successfully changed.'],
        },
        refreshToken : {
          endpoint: 'courses/auth/refresh/token',
          method: 'post',
          redirect: {
            success: null,
            failure: null,
          }
        },
        token: {
          class: NbAuthJWTToken,
          key:'token'
        },
        errors: {
          key: 'msg',
        },
        messages: {
          key: 'msg',
        }
      }),
    ],
    forms: {
      requestPassword: {
        redirectDelay: 3000,
      },
      resetPassword: {
        redirectDelay: 2500,
      }
    },
  }).providers,
  AuthGuard,
  WINDOW_PROVIDERS,
  ErrorLogService,
  { provide: ErrorHandler, useClass: GlobalErrorHandler},
  { provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: function (req:any) { return req.url === environment.baseUrl+'courses/auth/refresh/token'}},
  { provide: HTTP_INTERCEPTORS, useClass: MiraseeInterceptor, multi: true},
  { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  AnalyticsService,
  LayoutService,
  ScriptService,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    };
  }
}
