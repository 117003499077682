import { Component, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbThemeService, NbMenuItem } from '@nebular/theme';
import { Router } from "@angular/router";
import { takeUntil, map, distinctUntilChanged } from 'rxjs/operators' ;
import { combineLatest, Observable, Subject } from 'rxjs';
import { NbAuthToken, NbAuthService} from '@nebular/auth';
import { Course, Module, CourseData } from 'app/@core/data/courses';
import { User, UserData } from 'app/@core/data/users';
import { ScriptService } from 'app/@core/utils/script.service';
import { Title } from '@angular/platform-browser';
import { MENU_ITEMS, USER_MENU } from 'app/app-menu';

@Component({
  selector: 'ngx-header-course',
  styleUrls: ['header-course.component.scss'],
  templateUrl: 'header-course.component.html',
})

export class HeaderCourseComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$: Subject<void> = new Subject<void>();

  course:Course;
  // progress: Observable<Progress>;

  onlyPicture: boolean = true;
  showMenu:boolean = false;
  slug:string = '';

  user$: Observable<User>;
  isAdmin$: Observable<boolean>;
  hasTygpro$: Observable<boolean>;

  completed:number = 0;
  unread:number = 0;
  window:any = {Beacon:''};

  mainMenu:NbMenuItem[] = MENU_ITEMS;
  userMenu:NbMenuItem[] = USER_MENU;

  constructor(
    private menuService: NbMenuService,
    private router: Router,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: NbAuthService,
    private coursesService: CourseData,
    private userService: UserData,
    private scriptService:ScriptService,
    private titleService: Title,
  ) {
    // this.coursesService.loadAll();
    this.authService.onTokenChange()
    .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe((token: NbAuthToken) => {
      if (token.isValid()) {
        this.userService.load();
        // this.user = token.getPayload().data; // here we receive a payload from the token and assigns it to our `user` variable
      }
    });
  }

  ngOnInit() {
    combineLatest(
      [
        this.userService.isAdmin,
        this.coursesService.hasTygpro,
        this.coursesService.hasAces
      ]
    )
    .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe({
      next: ([isAdmin, hasTygp, hasAces]:any) => {
        MENU_ITEMS[0].hidden = !hasAces;
        MENU_ITEMS[1].hidden = !hasTygp;
        MENU_ITEMS[4].hidden = !isAdmin;
        this.mainMenu = MENU_ITEMS;
      },
      complete: () => console.log('complete'),
      }
    );

    this.user$ = this.userService.user;
    const { md } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange().pipe(
      map(([, currentBreakpoint]) => currentBreakpoint.width < md),
      takeUntil(this.destroy$),
    ).subscribe((isLessThanMd: boolean) => this.onlyPicture = !isLessThanMd);

    this.menuService.onItemClick()
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => this.showMenu = false);

    combineLatest (
      [
       this.coursesService.course,
       this.userService.user
      ]
    )
    .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe({
      next: ([course, user]:any) => {
        this.course = course;
        this.titleService.setTitle(this.course.name);
        this.initBeacon(course, user);
      },
      complete: () => console.log('This is how it ends!'),
    });

  }

  ngAfterViewInit() {

  }

  initBeacon(course:Course, user:User){
    if(course.hasOwnProperty('options')) {
      if(course.options.beacon) {
        this.scriptService.load('beacon').then((data:any) => {
          this.window = data[0].window;
          this.window.Beacon('init', course.options.beacon);
          if(user.email) {
            this.window.Beacon('identify', {name:user.firstname + " " + user.lastname, email:user.email});
          }
        }).catch((error:any) => console.log(error));
      }
    }
  }

  parseHTML(text:string) :string
  {
    let txt = document.createElement("textarea");
    txt.innerHTML = text;
    return txt.value.replace(/<[^>]*>/g, '');
  }

  getProgress(course:Course) :number
  {
    let totallesson = 0;
    let modules = course.modules;
    modules.forEach((module:Module, index:number) => {
      totallesson+= module.lessons.length;
    });
    return (this.completed / totallesson) * 100
  }

  goToProfile():void
  {
    this.router.navigate(['courses/my-profile']);
  }

  toggleSidebar(): boolean {
    this.showMenu = !this.showMenu;
    // this.sidebarService.toggle(false, 'right');
    // this.layoutService.changeLayoutSize();
    return false;
  }

  navigateTo(course: string, route:string): void {
    this.router.navigate(['course',course, route]);
  }

  getLink(slug:string):string {
    return `${slug}/notifications`;
  }

  ngOnDestroy() {
    if(typeof this.window.Beacon === "function") {
      this.window.Beacon('destroy');
    }
    this.titleService.setTitle('Mirasee Courses');
    this.destroy$.next();
    this.destroy$.complete();
  }

}
