import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <p class="mb-0 text-disabled">Mirasee &middot; All Rights Reserved &copy; {{year}}</p>
    `,
})
export class FooterComponent {
  year:number = new Date().getFullYear();
}
