import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { of as observableOf,  Observable,  BehaviorSubject } from 'rxjs';
import { Directory, DirectoryData } from '../data/directory';
import { ApiResponse } from 'app/@core/data/api-response';

@Injectable()
export class DirectoryService extends DirectoryData {

  baseUrl: string = environment.baseUrl + 'courses/directory';

  private _directory = new BehaviorSubject<Directory[]>([]);
  private directoryStore: { directory: Directory[] } = { directory: [] };
  readonly directory = this._directory.asObservable();

  constructor(private http: HttpClient) {super()}

  loadAll(course_id: number) :void
  {
    // return this.http.get<ApiResponse>(this.baseUrl + 'all');
    this.http.get<ApiResponse>(this.baseUrl + '/' + course_id + '/all').subscribe({
      next: (result:ApiResponse) => {
        if(result.status == 'ok') {
          this.directoryStore.directory = result.data;
          this._directory.next(Object.assign({}, this.directoryStore).directory);
        }
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    })
  }

  load(id: number) :Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(this.baseUrl +'/profile/'+id)
  }

  unload() :void
  {
    this.directoryStore.directory = [];
    this._directory.next(Object.assign({}, this.directoryStore).directory);
  }

  getMentions(course_id:number) :Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(this.baseUrl + '/' + course_id + '/mentions');
  }

}
