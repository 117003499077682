import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { takeUntil } from 'rxjs/operators' ;
import { Subject } from 'rxjs';
import { CoachContent, FormData } from 'app/@core/data/form';
import { ApiResponse } from 'app/@core/data/api-response';

@Component({
  selector: 'ngx-coach-content',
  templateUrl: './coach-content.component.html',
})
export class CoachContentComponent implements AfterViewInit, OnDestroy {
  @Input() formid:number;

  private destroy$: Subject<void> = new Subject<void>();

  content:CoachContent;
  video:any = {type:'vimeo', id:''};
  loaded:boolean = false;

  constructor(
    private formService: FormData,
  ) { }

  ngAfterViewInit() {
    this.formService.getCoachContent(+this.formid)
    .pipe(takeUntil(this.destroy$))
    .subscribe((result:ApiResponse) => {
      this.loaded = true;
      if(result.status == 'ok') {
        this.content = result.data;
        this.video.id = result.data.video;
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
