import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { takeUntil, delay, distinctUntilChanged, takeWhile, tap, map } from 'rxjs/operators' ;
import { Subject, BehaviorSubject, timer } from 'rxjs';
import { Course, CourseData } from 'app/@core/data/courses';
import * as moment from 'moment-timezone';
import { environment } from 'environments/environment';
import { ApiResponse } from 'app/@core/data/api-response';

@Component({
  selector: 'ngx-pwyc',
  templateUrl: 'pwyc.component.html',
  styleUrls: ['./pwyc.component.scss'],
})

export class PwycComponent implements OnInit, OnDestroy  {
  @ViewChild('dialog', { static: true }) dialog!: TemplateRef<any>;
  @ViewChild('confirmdialog', { static: true }) confirmdialog!: TemplateRef<any>;
  @ViewChild('dialogDiv', { read: ElementRef }) dialogDiv: ElementRef<HTMLElement>;

  private destroy$: Subject<void> = new Subject<void>();

  title:string = "";
  pwycForm: FormGroup;
  submitted:boolean = false;
  error:string;
  course:Course;
  video:any;
  enddate:moment.Moment;
  startdate:moment.Moment;
  tz:string = 'America/New_York';
  today:moment.Moment = moment.tz(moment(), this.tz);
  // today:moment.Moment = moment.tz('2023-06-14 00:00:01', this.tz);
  isSkipDate:moment.Moment;
  isFinalDate:moment.Moment;
  days:number = 0;
  skipdays:number = 28;
  isSkipped:boolean = false;
  isManual:boolean = false;
  showForm:boolean = false;
  showMessage:boolean = true;
  watchedVideo$ = new BehaviorSubject(false);
  msg:string;
  errorMsg:string;
  dialogRef:NbDialogRef<any>;
  confirmRef:NbDialogRef<any>;
  counter = 25;

  @Input() courseid: number;
  @Output() pwycOutput = new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private coursesService: CourseData,
    private dialogService: NbDialogService,
  ) {}

  ngOnInit() {
    this.coursesService.courses
    .pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$),
      delay(400),
      map((courses:Course[]) => courses.filter((course:Course) => course.id == this.courseid))
    )
    .subscribe((courses:Course[]) => {
      this.course = courses[0];
      this.title = this.course.id === 54 ? "Hybrid Course Launch" : "Hybrid Course University";
      if([62,64,65,66].includes(this.course.id)) {
        this.title =  'Hybrid Course University – AI Edition';
        this.video = {'id':'594rwruv36', 'type':'wistia'};
        this.skipdays = 29;
      } else {
        this.video = {'id':'mm1cx6bz3i', 'type':'wistia'};
      }

      if(environment.baseUrl == 'https://api.mirasee.com/staging/') {
        this.today = moment.tz(this.course.options?.today, this.tz);
      }
      if(this.course && this.course.course_enddate) {
        this.startdate = moment.tz(this.course.course_startdate, this.tz);
        this.enddate = moment.tz(this.course.course_enddate, this.tz);
        if(this.enddate.isAfter(this.today)) {
          this.isSkipDate = this.enddate.clone().add(1, 'days');
        } else {
          this.isSkipDate = this.today.clone().add(1, 'days');
        }
        this.isFinalDate = this.startdate.clone().add(this.skipdays, 'days');
        this.days = this.enddate.diff(this.startdate, 'days');
        if((this.skipdays-1) < this.days) {
          this.isSkipped = true;
          this.showForm = this.today.isAfter(this.enddate);
          if(this.showForm) {
            this.pwycOutput.emit(this.showForm);
            this.open(this.dialog);
          }
        } else if (13 < this.days && this.days <= 27) {
          this.showForm = this.today.isAfter(this.enddate);
          if(this.showForm) {
            this.pwycOutput.emit(this.showForm);
            this.open(this.dialog);
          }
        }
        // this.showForm =  true;
        // this.open(this.dialog);
        this.watchedVideo$.next(JSON.parse(localStorage.getItem(this.video.id+'_watched')) || false);
      }
    });

    this.pwycForm = this.formBuilder.group({
      pwyc:  new FormControl(200, [Validators.required, Validators.min(10), Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')]),
      accept: new FormControl(false, Validators.requiredTrue)
    });

  }

  hasNotPaid(coursedate:Date) :boolean
  {
    let todate = new Date('2031-12-31 23:59:59');
    let enddate = new Date(coursedate);
    return enddate.getTime() != todate.getTime();
  }

  // convenience getter for easy access to form fields
  get f() { return this.pwycForm.controls; }

  openManual() {
    this.isSkipped = true;
    this.isManual = true;
    this.open(this.dialog);
  }

  open(dialog: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(dialog, {
      autoFocus: false,
      closeOnBackdropClick: false,
      closeOnEsc :false,
      hasBackdrop: true,
    });
  }

  confirmOpen(confirmdialog:TemplateRef<any>) {
    this.confirmRef = this.dialogService.open(confirmdialog, {
      autoFocus: false,
      closeOnBackdropClick: false,
      closeOnEsc :false,
      hasBackdrop: true,
    });
  }

  confirmClose() :void
  {
    this.confirmRef.close();
  }

  onFormSubmit() :void
  {
    if (this.pwycForm.valid) {
      this.confirmOpen(this.confirmdialog);
    }
  }

  updatePayment() :void
  {
    this.confirmRef.close();
    var data = {'amount' : this.pwycForm.get('pwyc').value, course:this.course.slug};
    this.submitted = true;
    this.coursesService.updatePwyc(data, this.course)
    .pipe(takeUntil(this.destroy$), delay(200))
    .subscribe((result:ApiResponse) => {
      this.submitted = false;
      if( result.status == 'error') {
        // if([62,64,65,66].indexOf(this.course?.id) !== -1) {
          // this.errorMsg = "<p>There was an error processing your Pay What You Can amount but don't worry! We've noted the error and our support team will reach out to you to complete your payment.</p><p>In the meantime, you can click on the \"I'm not ready to decide yet\" link to hide this popup (prior to June 27).</p>";
        // } else {
        this.errorMsg = "<p>There was an error processing your Pay What You Can amount. Please contact support@mirasee.com so we can complete your payment and extend your course access.</p>";
        // }
      } else if(result.status == 'ok') {
        this.showMessage = false;
        this.msg = "<p>Thanks for choosing your Pay What You Can amount! Your first payment of this amount will be drawn this week, with "+ ([62,64,65,66].indexOf(this.course?.id) !== -1 ? 5 : 3) +" further monthly payments of the same amount to follow.</p><p>You now have permanent access to the full "+this.title+" program -- so continue making good use of it, and enjoy the impact, income, and freedom you gain from your hybrid course!</p>"
        this.reloadPage();
      }
    });
  }

  onSkip() {
    this.coursesService.skipPwyc(this.isSkipDate.format('YYYY-MM-DD HH:mm:ss'), this.course)
    .then((result:ApiResponse) => {
      if(result.status == 'ok') {
        this.showMessage = false;
        this.msg = "Thanks for letting us know you're not quite ready to choose your pay-what-you-can amount! We will ask you again soon (within the next 14 days), so you have a little longer to make use of the program before you decide."
        this.reloadPage();
      }
    });
  }

  closeDialog():void
  {
    this.dialogRef.close();
    window.location.reload();
  }

  reloadPage():void
  {
    timer(1000, 1000) //Initial delay 1 seconds and interval countdown also 1 second
    .pipe(takeWhile( () => this.counter > 0 ), tap(() => this.counter--))
    .subscribe( () => {
      if(this.counter == 0) {
        this.dialogRef.close();
        window.location.reload();
      }
    });
  }

  videoOutput(video:any):void
  {
    video.bind("secondchange", (s:number) => {
      if (s > 342) {
        this.showVidoContent();
      }
    });
    video.bind("end", () => this.showVidoContent());
  }

  showVidoContent() :void
  {
    if(!this.watchedVideo$.getValue()) {
      this.watchedVideo$.next(true);
      localStorage.setItem(this.video.id+'_watched', "true");
      this.dialogDiv.nativeElement.click();
    }
  }

  ngOnDestroy() {
    this.courseid = 0;
    this.showForm = false;
    if(this.dialogRef) {
      this.dialogRef.close();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
