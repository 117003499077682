import { Observable } from 'rxjs';
import { ApiResponse } from 'app/@core/data/api-response';

export interface Activity {
  id?:number|null;
  user_id?:number;
  type:string;
  activity:string;
  title:string;
  content:string;
  url:string;
  ref_id:number;
  agent?:string|null;
  ip?:string|null;
  status?:number|null;
  created_date?:string|null;
}

export abstract class ActivityData {
  abstract activity:Observable<Activity[]>;
  abstract loadAll():void;
  abstract loadNext(page:number, limit:number):Observable<ApiResponse>;
  abstract load(course_id: number):void;
  abstract unload():void;
  abstract getHSContact(email: string):Observable<ApiResponse>;
  abstract save(data: Activity):Promise<ApiResponse>;
  abstract saveForm(formid:string, fields:any):Promise<ApiResponse>
}
