<div class="alert alert-info py-4 text-center" *ngIf="days > 14 && !this.isSkipped && hasNotPaid(course.course_enddate)">
  <h3 class="mb-0"><button type="button" name="button" class="btn-link link-primary link-offset-2 p-0" (click)="openManual()">Click here if you're ready to Pay What You Can to retain full, ongoing access to {{title}}</button></h3>
</div>
<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card class="nb-card-pwyc">
    <nb-card-header>
      <h1 class="text-center w-100 mb-0">
        Choose Your “Pay What You Can” <br class="d-none d-sm-block">Amount for <strong>{{title}}</strong>
      </h1>
    </nb-card-header>
    <nb-card-body #dialogDiv class="layout-padding">
      <div class="row mx-0">
        <div class="col-12">
          <div [ngVideo]="video" class="mb-4" (videoOutput)='videoOutput($event)'></div>
          <h3 *ngIf="!watchedVideo$.value" class="text-center"><strong>Watch to the end of the video to access the payment form.</strong></h3>
        </div>
        <div class="col-12 slidein" *ngIf="watchedVideo$ | async">
          <div class="d-block slidein" *ngIf="showMessage">
            <h3><strong>How it works:</strong></h3>
            <div class="d-block" *ngIf="[33,34,35,36,54].indexOf(course?.id) !== -1">
              <ul class="mb-4 ps-4">
                <li>Choose your Pay What You Can amount to retain full, ongoing access to {{title}}.</li>
                <li>You will make four monthly payments of the same amount.</li>
                <li>Any amount you choose over $10 is perfect. (We’ve only set a minimum because amounts under $10 risk getting flagged as suspicious by credit card companies.)</li>
                <li>Please don’t pay MORE than you can afford right now.</li>
                <li>Please don’t pay LESS than is justified by the value you expect to gain from the program. There is no upper limit.</li>
                <li>Keep in mind that we guarantee you’ll make back your investment within 6 months... or we will refund you the difference.</li>
              </ul>
              <p><strong>Enter your payment amount into the box below, and keep in mind that you’ll make 4 monthly payments of whatever amount you enter. Then check the checkbox to confirm, and click the button to send it to us</strong>.</p>
            </div>
            <div class="d-block" *ngIf="[62,64,65,66].indexOf(course?.id) !== -1">
              <ul class="mb-4 ps-4">
                <li>Choose your pay-what-you-can amount to retain full, ongoing access to Hybrid Course University – AI Edition.</li>
                <li>You will make 6 monthly payments of the same amount. (The minimum is $10 per payment.)</li>
                <li>Whatever amount you choose is perfect! There is no catch. :-) </li>
                <li>Please pay what you can <em>afford</em> right now (<em>not</em> more).</li>
                <li>AND please pay an amount that <em>justifies the value you expect to gain from the program</em>. There is no upper limit.</li>
                <li>Remember: we guarantee you’ll make at least $6,000 in revenue by the end of the Hybrid Course University program, so please keep that promise in mind as you’re selecting your pay-what-you-can amount.</li>
              </ul>
              <p><strong>Now, enter your payment amount into the box below – NOTE: you will make 6 equal monthly payments of whatever amount you enter. Next, check the box to confirm your understanding, and click the button to send your payment confirmation to us.</strong></p>
            </div>
          <form [formGroup]="pwycForm" (ngSubmit)="onFormSubmit()" autocomplete="off">
              <div class="mb-4">
                <h4>Median monthly payment selected is $500.50</h4>
                <div class="input-group my-4">
                  <label for="" class="d-block d-md-none">I choose to make {{[62,64,65,66].indexOf(course?.id) !== -1 ? 6 : 4}} monthly payments of $</label>
                  <div class="input-group-prepend d-none d-md-block">
                    <span class="input-group-text" id="basic-addon3">I choose to make {{[62,64,65,66].indexOf(course?.id) !== -1 ? 6 : 4}} monthly payments of $</span>
                  </div>
                  <input nbInput type="number" min="10" step="10" id="pwyc" name="pwyc" class="form-control" placeholder="Enter Amount (e.g. $200)" formControlName="pwyc" required="true"
                    [status]="f.pwyc.dirty ? (f.pwyc.invalid  ? 'danger' : 'success') : 'basic'"
                    [attr.aria-invalid]="f.pwyc.invalid && f.pwyc.touched ? true : null"
                    [ngClass]="{ 'is-invalid': f.pwyc.invalid && f.pwyc.touched  }"/>
                  <div *ngIf="f.pwyc.touched && f.pwyc.invalid" class="invalid-feedback">
                    <div *ngIf="f.pwyc.errors?.required">This is required field</div>
                    <div *ngIf="f.pwyc.errors?.pattern">Please enter valid amount</div>
                    <div *ngIf="f.pwyc.errors?.min">This can not be empty or less then $10, there's a minimum amount of $10/month ({{[62,64,65,66].indexOf(course?.id) !== -1 ? '$60' : '$40'}} total)</div>
                  </div>
                </div>
              </div>
              <div class="mb-4 form-check pb-3 pt-4 ps-0">
                <nb-checkbox [status]="f.accept.touched && f.accept.errors ? 'danger' : 'basic'" formControlName="accept">I understand that I am committing to {{[62,64,65,66].indexOf(course?.id) !== -1 ? 6 : 4}} equal monthly payments and that my first payment will be drawn this week.</nb-checkbox>
                <!-- <input class="form-check-input" type="checkbox" name="accept" formControlName="accept" id="acceptCheck" required="true" [ngClass]="{ 'is-invalid': f.pwyc.invalid && f.accept.touched }" required>
                <label class="form-check-label ms-2" for="acceptCheck"  [ngClass]="{ 'is-invalid': f.accept.touched && f.accept.errors }">
                  I understand that I am committing to {{[62,64,65,66].indexOf(course?.id) !== -1 ? 6 : 4}} equal monthly payments and that my first payment will be drawn this week.
                </label> -->
                <div *ngIf="f.pwyc.invalid && f.accept.touched" class="invalid-feedback">
                  <div *ngIf="f.accept.errors.required">You must agree before submitting.</div>
                </div>
              </div>
              <div class="mb-4 alert alert-danger" *ngIf="errorMsg">
                <div class="mb-0" [innerHTML]="errorMsg | sanitizeHtml:[]"></div>
              </div>
              <div class="mb-4">
                <button type="submit" nbButton type="submit" class="mb-4 d-block me-2" status="primary" [disabled]="!pwycForm.valid || (pwycForm.valid && submitted)" [nbSpinner]="pwycForm.valid && submitted" nbSpinnerStatus="info" nbSpinnerSize="large" nbSpinnerMessage="">Confirm My Choice!<nb-icon icon="arrow-ios-forward-outline" class="ms-1"></nb-icon></button>
                <button *ngIf="!isSkipped" type="button" (click)="onSkip()" class="btn btn-link m-0 p-0">I’m not ready to decide yet -- ask me again later.</button>
                <button *ngIf="isManual" type="button" (click)="dialogRef.close()" class="btn btn-link m-0 p-0">Cancel</button>
                <!-- <button (click)="confirmOpen(confirmdialog)" class="btn btn-warning m-0 p-0">confirmOpen</button> -->
              </div>
            </form>
          </div>
          <div class="alert alert-info" *ngIf="!showMessage && msg">
            <div class="mb-0" [innerHTML]="msg | sanitizeHtml:[]"></div>
            <button type="button" nbButton class="my-4 me-2" status="info" (click)="closeDialog()">Continue to {{title}}</button>
            <span class="text-muted">Closes in {{counter}}</span>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<ng-template #confirmdialog let-data let-ref="confirmRef">
  <nb-card class="nb-card-pwyc">
    <nb-card-body class="text-center">
      <h1 class="text-center w-100 mb-4">
        You've chosen to make {{[62,64,65,66].indexOf(course?.id) !== -1 ? 6 : 4}} monthly payments of ${{pwycForm.get('pwyc').value}}
      </h1>
      <button type="button" nbButton class="me-2" status="info" (click)="updatePayment()">Yes, I Confirm My Choice!<nb-icon icon="arrow-ios-forward-outline" class="ms-1"></nb-icon></button>
      <button (click)="confirmClose()" type="button" class="btn btn-default m-0 p-0">Cancel</button>
    </nb-card-body>
  </nb-card>
</ng-template>
