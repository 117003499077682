import { Directive, OnDestroy, HostListener, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[iframeAutoHeight]'
})

export class ngIframeAutoHeightDirective implements OnDestroy {
  @Input('iframeAutoHeight') sourceName: string;

  constructor(private el: ElementRef) { }

  @HostListener('window:message', ['$event'])

  onMessage(event: any) {
    if ('undefined' !== typeof event.data &&
    'undefined' !== typeof event.data.type &&
    event.data.type === 'resize' &&
    'undefined' !== typeof event.data.source &&
    event.data.source === this.sourceName) {
      this.resizeIframe(event.data);

    }
  }

  resizeIframe(data:any):void {
    if ('undefined' !== typeof data.value && 'undefined' !== typeof data.value.height) {
      if (this.el.nativeElement.clientHeight !== Number(data.value.height)) {
        this.el.nativeElement.style.height = data.value.height + 'px';
      }
    }
  }

  ngOnDestroy() {
    // TODO: destroy @HostListener watcher?
  }


}
// import {Directive, ElementRef, OnInit, Renderer2, HostListener, Input} from "@angular/core";
//
// @Directive({
//   selector: "[iframeAutoHeight]"
// })
// export class ngIframeAutoHeightDirective implements OnInit {
//   @Input('iframeAutoHeight') sourceName: string;
//
//   private el: any;
//   private renderer: Renderer2;
//   private prevHeight: number;
//   private sameCount: number;
//
//   constructor(_elementRef: ElementRef, _renderer: Renderer2) {
//     this.el = _elementRef.nativeElement;
//     this.renderer = _renderer;
//   }
//
//   ngOnInit() {
//     const self = this;
//     if (this.el.tagName === "IFRAME") {
//       this.renderer.listen(this.el, "load", () => {
//         self.prevHeight = 0;
//         self.sameCount = 0;
//         setTimeout(() => {
//           self.setHeight();
//         }, 20);
//       });
//     }
//   }
//
//   @HostListener('window:message', ['$event'])
//   onMessage(event: any) {
//     if ('undefined' !== typeof event.data && 'undefined' !== typeof event.data.type && event.data.type === 'resize' && 'undefined' !== typeof event.data.source) {
//       this.resizeIframe(event.data);
//     }
//   }
//
//   resizeIframe(data) {
//     if ('undefined' !== typeof data.value && 'undefined' !== typeof data.value.height) {
//       if (this.el.clientHeight !== Number(data.value.height)) {
//         this.el.style.height = data.value.height + 'px';
//       }
//     }
//   }
//
//   setHeight() {
//     const self = this;
//     var receiver = this.el.contentWindow;
//     receiver.postMessage({"a":"getOffsetHeight"}, '*');
//     receiver.postMessage({"a":"getOffsetTop"}, '*');
//     // let windowHeight3 = window.innerHeight - 7;
//     // this.el.style.height = windowHeight3 + 'px';
//     if (this.el.contentWindow.document.body.scrollHeight !== this.prevHeight) {
//       this.sameCount = 0;
//       this.prevHeight = this.el.contentWindow.document.body.scrollHeight;
//       this.renderer.setElementStyle(
//         self.el,
//         "height",
//         this.el.contentWindow.document.body.scrollHeight + "px"
//       );
//       setTimeout(() => {
//         self.setHeight();
//       }, 50);
//
//     } else {
//       this.sameCount++;
//       if (this.sameCount < 2) {
//         setTimeout(() => {
//           self.setHeight();
//         }, 50);
//       }
//     }
//   }
// }
