import { Inject, Injectable, Injector, ErrorHandler } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable  } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { NbAuthToken } from '@nebular/auth';
import { NB_AUTH_TOKEN_INTERCEPTOR_FILTER, NbAuthService } from '@nebular/auth';

@Injectable()
export class MiraseeInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
    @Inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER) protected filter:any
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // do not intercept request whose urls are filtered by the injected filter
    if (!this.filter(req)) {
      return this.authService.isAuthenticatedOrRefresh()
      .pipe(switchMap(authenticated => {
        if (authenticated) {
          return this.authService.getToken().pipe(
            switchMap((token: NbAuthToken) => {
              const JWT = `Bearer ${token.getValue()}`;
              req = req.clone({
                headers: req.headers.set('Cache-Control', 'no-cache')
                .set('Pragma', 'no-cache')
                .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
                .set('If-Modified-Since', '0'),
                setHeaders: {
                  Authorization: JWT,
                },
              });
              return next.handle(req);
            }),
          )
        } else {
          // Request is sent to server without authentication so that the client code
          // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
          return next.handle(req);
        }
      }))
    } else {
      return next.handle(req);
    }
  }

  protected get authService(): NbAuthService {
    return this.injector.get(NbAuthService);
  }

}

export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
    .pipe(
      // retry(1),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        return next.handle(request);
      })
    )
  }
}

@Injectable()
export class ErrorLogService {
  private name: String = 'ErrorLogService';

  logError(error:any) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else {
      if (error instanceof HttpErrorResponse) {
        console.error('There was an HTTP error.', error.message, 'Status code:', (<HttpErrorResponse>error).status);
      } else if (error instanceof TypeError) {
        console.error('There was a Type error.', error.message);
      } else if (error instanceof Error) {
        console.error('There was a general error.', error.message);
      } else {
        console.error('Nobody threw an error but something happened!', error);
      }
    }
  }
}

// global error handler that utilizes the above created service (ideally in its own file)

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private errorLogService: ErrorLogService) {
    super();
  }

  handleError(error:any) :void
  {
    this.errorLogService.logError(error);
  }
}
