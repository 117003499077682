<div class="actions w-100">
  <div class="layout-padding pt-3 w-100">
    <div class="row row-cols-1 row-cols-md-2 mt-2 mt-md-0" *ngIf="!iscommunity && course">
      <div class="col-12 col-md-7 mb-4 mb-md-0" *ngIf="course.options.actionplan">
        <ngx-action-plan [course]="course" color="text-white h-100">
          <h2 class="text-white">
            <nb-icon icon="actionplan" pack="mirasee" class="me-2 float-start"></nb-icon>Your Action Plan
          </h2>
          <p class="text-light mt-2 mb-0">Actions that you add throughout the program will appear here</p>
        </ngx-action-plan>
      </div>
      <div class="col-12 mb-4 mb-md-0" [ngClass]="{'col-md-5': course.options.actionplan, 'col-md-7': !course.options.actionplan}">
        <div class="event-list p-1 h-100">
          <ngx-course-event [course]="course" [filter]="eventFilter" [link]="eventLink" outline="transparent"></ngx-course-event>
        </div>
      </div>
      <div class="col-12 col-md-5" *ngIf="!course.options.actionplan">
        <div class="event-list p-3 h-100">
          <h2 class="text-white">
            <nb-icon icon="users" pack="mirasee" class="me-2 float-start text-white"></nb-icon>Make Connections
          </h2>
          <p class="mt-3 mb-0"><a [routerLink]="[basepath, course.slug, 'directory']" class="text-white pointer">Discover and connect with everyone in {{course.name}}<nb-icon icon="arrow-ios-forward-outline" class=""></nb-icon></a></p>
        </div>
      </div>
    </div>
    <div class="row mx-0 w-100 align-items-center" *ngIf="iscommunity && course">
      <div class="col-12 px-0">
        <h6 class="text-uppercase text-white" *ngIf="course.name">{{course.name}} Community</h6>
        <h2 class="mt-4 text-white">
          <nb-icon icon="find-peeps" pack="mirasee" class="me-2 float-start"></nb-icon>Meet Interesting People
        </h2>
        <p class="text-light mt-2 mb-1 ms-4"><a class="text-white pointer" [routerLink]="[basepath, course.slug, 'directory']">See Full Directory<nb-icon icon="arrow-ios-forward-outline" class="ms-1"></nb-icon></a></p>
        <ngx-peeps-find [course]="course" [filter]="getFilter('random', 6, 6, 3)" [col]="4"></ngx-peeps-find>
      </div>
    </div>
  </div>
</div>
