import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse } from 'app/@core/data/api-response';

export interface Options {
  logo: string;
  is_cohort: boolean;
  slack:string;
  actionplan:boolean;
  beacon:boolean|string;
  today?:string;
  mist?:string;
  directory?:boolean;
}

export interface Course {
  id:number;
  name: string;
  slug: string;
  description: string;
  start_date: string;
  end_date: string;
  attendee:number;
  options: Options;
  modules: Module[];
  masterclasses: Module[];
  nextsteps?:any;
  course_enddate:Date;
  course_startdate:Date;
  course_status:number;
  progress:number;
}

export interface Module {
  id:number;
  slug: string;
  course_id?:number;
  module_no?:number;
  name: string;
  description: string;
  is_pack:number;
  is_dependent:number;
  start_date: Date;
  end_date?: Date;
  options?: any;
  status: number;
  lessons:Lesson[];
  is_owned:number;
  expert?:Expert;
}

export interface Lesson {
  id:number;
  slug?: string;
  course_id:number;
  module_id:number;
  lesson_no:number;
  name: string;
  type:string;
  description: string;
  content:string;
  is_dependent:number;
  start_date: Date;
  end_date: Date;
  options?: any;
  status: number;
  completed?:string;
  progress?:any;
}

export interface Expert {
  name:string;
  bio:string;
  title:string;
  avatar:string;
}

export interface Masterclass {
  id:number;
  slug: string;
  name: string;
  description: string;
  is_pack:number;
  is_dependent:number;
  start_date: string;
  end_date?: string;
  options?: any;
  status: number;
  lessons:MasterclassLesson[];
  is_owned:number;
  expert?:Expert;
}

export interface MasterclassLesson {
  id:number;
  slug: string;
  name: string;
  description:string;
  start_date: string;
  options:any;
  is_dependent:number;
  completed:string;
}

export interface NextStep {
  id:number;
  name:string;
  lesson_id:number;
  module_id:number;
  description:string;
  review: string;
  completion_date: string;
  end_date: string;
}

export abstract class CourseData {
  abstract courses: Observable<Course[]>;
  abstract course:Observable<Course>;
  abstract jumbotron:Observable<boolean>;
  abstract hasTygpro:BehaviorSubject<boolean>;
  abstract hasAces:BehaviorSubject<boolean>;
  abstract showJumbotron(message:boolean):void;
  abstract loadAll():void;
  abstract load(slug: string):void;
  abstract unload():void;
  abstract getCourseProperty(slug:string, property:string): any;
  abstract getBreadcrumb(): Observable<any>;
  abstract setBreadcrumb(breadcrumbs:any):void;
  abstract getCurrentModule() :Module;
  abstract setCurrentModule(module: Module) :void;
  abstract getCurrentMasterclass() :Module;
  abstract setCurrentMasterclass(module: Module) :void;
  abstract getModule(id: string|number): Observable<ApiResponse>;
  abstract getMasterclass(id: string|number): Observable<ApiResponse>;
  abstract getNextUnlock(): Observable<ApiResponse>;
  abstract hasAccesstoMasterclass(slug:string):boolean;
  abstract getCurrentLesson():Lesson;
  abstract setCurrentLesson(lesson: Lesson):void;
  abstract getLesson(course:string, lessonsslug: string): Observable<ApiResponse>;
  abstract unlockMasterclass(postData:any):Promise<boolean>;
  abstract addToNextStep(postData:Object):Promise<ApiResponse>;
  abstract activityReview(id:number, postData:Object):Promise<ApiResponse>;
  abstract skipPwyc(nextdate:string, course:Course):Promise<ApiResponse>;
  abstract updatePwyc(postData:Object, course:Course):  Observable<ApiResponse>;
}
