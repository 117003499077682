import { Component, OnDestroy, OnInit, OnChanges, Input} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Course, Lesson, NextStep, CourseData } from 'app/@core/data/courses';
import { NbToastrService } from '@nebular/theme';
import { NbDateService } from '@nebular/theme';
import { ApiResponse } from 'app/@core/data/api-response';
import { IDatePickerDirectiveConfig } from 'ng2-date-picker';
import * as moment from 'moment-timezone';

@Component({
  selector: 'ngx-action',
  styleUrls: ['./action.component.scss'],
  templateUrl: './action.component.html'
})

export class ActionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() course:Course;
  @Input() lesson:Lesson;
  @Input() is_pack:number;

  submitted:boolean = false;
  loading:boolean = false;
  ngModelDate:Date = new Date();

  action:any;
  min: Date;
  actionForm:FormGroup;
  reviewForm:FormGroup;
  dpConfig:IDatePickerDirectiveConfig = {format:'YYYY-MM-DD',showTwentyFourHours:true};

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: NbToastrService,
    private coursesService: CourseData,
    protected dateService: NbDateService<Date>
  ) {
    this.min = this.dateService.addDay(this.dateService.today(),0);
  }

  ngOnInit() {

  }

  ngOnChanges(){
    this.actionForm = this.formBuilder.group({
      name:[this.lesson.name, [Validators.required]],
      description: [''],
      completion_date: ['', [Validators.required]]
    });
    this.reviewForm = this.formBuilder.group({
      review: ['', [Validators.required]],
    });
    if(this.course.nextsteps.length > 0) {
      let action:NextStep[] = this.course.nextsteps.filter((nextstep:NextStep) => nextstep.lesson_id == this.lesson.id);
      if(action.length > 0) {
        this.action = action[0];
        if(!this.action.end_date) {
          this.actionForm.get('name').setValue(this.action.name);
          this.actionForm.get('description').setValue(this.action.description);
          this.actionForm.get('completion_date').setValue(moment(this.action.completion_date).utc().format('Y-MM-DD'));
          // let completion_date = new Date(this.action.completion_date);
          // this.ngModelDate = completion_date;
        }
      }
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.actionForm.controls; }
  get g() { return this.reviewForm.controls; }

  addToNextStep() {
    this.submitted = true;
    if (this.actionForm.valid) {
      this.loading = true;
      const postData = {course_id:this.course.id, lesson_id:this.lesson.id, name:this.actionForm.get('name').value, start_date: moment().utc().format('Y-MM-DD HH:mm:ss'),description:this.actionForm.get('description').value, completion_date:moment(this.actionForm.get('completion_date').value).utc().format('Y-MM-DD HH:mm:ss')};
      this.coursesService.addToNextStep(postData).then( (result:ApiResponse) => {
        this.toastrService.show(result.msg, 'Your action step added to your action plan', { status: 'success'});
        this.loading = false;
      }); //mark module lessons completed
    }
  }

  onActivityReview(action: any) {
    this.submitted = true;
    if (this.actionForm.valid) {
      const postData = {description:this.actionForm.get('description').value, completion_date:moment(this.actionForm.get('completion_date').value).utc().format('Y-MM-DD HH:mm:ss')};
      this.coursesService.activityReview(action.id, postData).then( (result:ApiResponse) => {
        this.toastrService.show(result.msg, 'Your action step has been updated', { status: 'success'});
        this.loading = false;
      }); //mark module lessons completed  }
    }
  }

  ngOnDestroy() {
  }

}
