<div *ngIf="!action">
  <form [formGroup]="actionForm" (ngSubmit)="addToNextStep()">
    <div *ngIf="lesson.content" [innerHTML]="lesson.content | sanitizeHtml:[]" ></div>
    <div>
      <div class="mb-4 mt-3">
        <input nbInput formControlName="name" class="form-control" id="inputName" placeholder="" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"/>
      </div>
      <div class="mb-4">
        <textarea rows="3" nbInput fullWidth class="form-control" formControlName="description" id="inputDescription" placeholder="Add your notes here" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <label for="targeCompletionDate" class="h6 text-uppercase me-2">Target completion date</label>
        <input nbInput formControlName="completion_date" class="form-control d-inline w-25" [dpDayPicker]="dpConfig" mode='daytime' theme="dp-material" id="targeCompletionDate" placeholder="Pick date" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.completion_date.errors }"/>
        <!-- <nb-datepicker #formpicker></nb-datepicker> -->
      </div>
      <div class="col-12 col-sm-6 text-start text-sm-end mt-3 mt-sm-0">
        <button nbButton status="primary" [nbSpinner]="loading" nbSpinnerStatus="info" nbSpinnerSize="small" nbSpinnerMessage="">Add to “Your Next Steps”</button>
      </div>
    </div>
  </form>
</div>
<div *ngIf="action">
  <div *ngIf="!action.end_date">
    <form [formGroup]="actionForm" (ngSubmit)="onActivityReview(action)">
      <div *ngIf="lesson.content" [innerHTML]="lesson.content | sanitizeHtml:[]" ></div>
      <div>
        <div class="mb-4 mt-3">
          <h3 class="pt-1"><nb-icon icon="info" class="float-start me-2"></nb-icon>{{action.name}}</h3>
        </div>
        <div class="mb-4">
          <textarea rows="3" nbInput fullWidth class="form-control" formControlName="description" id="inputDescription" placeholder="Add your notes here" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <label for="targeCompletionDate" class="h6 text-uppercase me-2">Target completion date</label>
          <input nbInput formControlName="completion_date" class="form-control d-inline w-25" [dpDayPicker]="dpConfig" mode='daytime' theme="dp-material" id="targeCompletionDate" placeholder="Pick date" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.completion_date.errors }"/>
          <!-- <nb-datepicker #formpicker [min]="min"></nb-datepicker> -->
        </div>
        <div class="col-12 col-sm-6 text-start text-sm-end mt-3 mt-sm-0">
          <button nbButton status="primary" [nbSpinner]="loading" nbSpinnerStatus="info" nbSpinnerSize="small" nbSpinnerMessage="">Update Next Step</button>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="action.end_date">
    <div *ngIf="lesson.content" [innerHTML]="lesson.content | sanitizeHtml:[]"></div>
    <div class="d-block mt-4">
      <h3 class="pt-1"><nb-icon icon="info" class="float-start me-2"></nb-icon>{{action.name}}</h3>
      <p>Complete on {{action.completion_date | momentFormat:'ll'}}</p>
      <p>{{action.description}}</p>
    </div>
  </div>
</div>
