import { Injectable, Inject} from "@angular/core";
import { WINDOW } from "app/@core/windowref.provider";

declare var document: any;

interface Scripts {
  name: string;
  src: string;
  windowref:string;
}

export const ScriptStore: Scripts[] = [
  {name:'hsform', src: '//js.hsforms.net/forms/v2.js', windowref:'hbspt'},
  {name:'hsformlegacy', src:'//js.hsforms.net/forms/v2-legacy.js', windowref:'hbspt'},
  {name:'wistia', src:'//fast.wistia.com/assets/external/E-v1.js', windowref:'Wistia'},
  {name:'vimeo', src:'//player.vimeo.com/api/player.js', windowref:'Vimeo'},
  {name:'beacon', src:'https://media.mirasee.com/js/beacon.js', windowref:'Beacon'},
  {name:'gsap', src:'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.10.4/gsap.min.js', windowref:'gsap'},
  {name:'Winwheel', src:'https://media.mirasee.com/js/Winwheel.min.js', windowref:'Winwheel'},
  {name:'json', src:'https://secure.ultracart.com/js/json3.min.js', windowref:'JSON' },
  {name:'uchf', src:'https://token.ultracart.com/checkout/checkout-hosted-fields-1.0.js', windowref:'UltraCartHostedFields' }
];

export interface  ScriptResult {
  status: string;
  script: string;
  loaded: string;
}

@Injectable()
export class ScriptService {

  private scripts: any = {};

  constructor(
    @Inject(WINDOW) private window: Window
  ) {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[name].loaded) {
        setTimeout(() => resolve({script: name, loaded: true, status: 'Already Loaded', window:this.window}), 500)
      } else {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {  //IE
          script.onreadystatechange = () => {
            if (script.readyState === "loaded" || script.readyState === "complete") {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              setTimeout(() => resolve({script: name, loaded: true, status: 'Loaded', window:this.window}), 500)
            }
          };
        } else {  //Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            setTimeout(() => resolve({script: name, loaded: true, status: 'Loaded', window:this.window}), 500)
          };
        }
        script.onerror = (error: any) => setTimeout(() => resolve({script: name, loaded: false, status: 'Not Loaded', window:this.window}), 500);
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

}
// import { DOCUMENT } from '@angular/common';
// import { Inject, Injectable } from '@angular/core';
// import { forkJoin, Observable, ReplaySubject } from 'rxjs';

// @Injectable({ providedIn: 'root' })
// export class ExternalScriptLoading {
//   private libraries: { [url: string]: ReplaySubject<unknown> } = {};

//   constructor(@Inject(DOCUMENT) private readonly document: Document) {}

//   public loadExternals(externals: string[]): Observable<unknown> {
//     return forkJoin(externals.map((external: string) => this.inject(external)));
//   }

//   private inject(link: string): Observable<unknown> {
//     if (this.libraries[link]) {
//       return this.libraries[link].asObservable();
//     }

//     this.libraries[link] = new ReplaySubject();

//     this.injectScript(link);

//     return this.libraries[link].asObservable();
//   }

//   private injectScript(url: string): void {
//     const script: HTMLScriptElement = this.document.createElement('script');

//     script.type = 'text/javascript';
//     script.src = url;
//     script.defer = true;
//     script.onload = () => {
//       this.libraries[url].next();
//       this.libraries[url].complete();
//     };

//     this.document.body.appendChild(script);
//   }
// }