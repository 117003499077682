import { Component, OnDestroy, OnInit, Input} from '@angular/core';
import { Router} from "@angular/router";
import { takeUntil, distinctUntilChanged } from 'rxjs/operators' ;
import { Subject } from 'rxjs';
import { Course, Module, CourseData } from 'app/@core/data/courses';
import { Progress, ProgresItem, ProgressData } from 'app/@core/data/progress';

@Component({
  selector: 'ngx-masterclass',
  templateUrl: './masterclass.component.html',
})

export class MasterclassComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @Input() course: Course;
  @Input() masterclass:Module;
  @Input() type?:string = 'my';
  @Input() size:any = 'medium';
  @Input() link?:boolean = false;

  loading:boolean = true;
  progress:number = 0;

  constructor(
    private router: Router,
    private coursesService: CourseData,
    private progressService: ProgressData,
  ) {}

  ngOnInit() {
    if(this.type == 'my') {
      this.calculateProgress();
    }
  }

  calculateProgress() {
    this.progressService.progress
    .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe((progress:Progress) => {
      if(progress.hasOwnProperty('masterclasses')) {
        let mlessons = this.masterclass.lessons;
        // const plessons = Object.keys(progress.masterclasses).pipe(map(key => progress.masterclasses[key]));
        let pmlessons:ProgresItem[] = [];
        mlessons.forEach((keys : any, vals :any) => {
          if (progress.masterclasses.hasOwnProperty(keys.id)) {
            pmlessons.push(progress.masterclasses[keys.id]);
          }
        })
        let completed = pmlessons.filter(lesson => lesson.status == 'Completed');
        this.progress = completed.length * 100 / mlessons.length;
      }
    });
  }

  goToModule(course:Course, masterclass: Module): boolean {
    if(masterclass.is_owned) {
      this.coursesService.setCurrentMasterclass(masterclass);
      this.coursesService.setBreadcrumb({'course':course.name, 'module':masterclass.name, 'masterclass':masterclass.name});
      this.router.navigate(['course', course.slug, 'masterclasses', masterclass.slug]);
    }
    return false;
  };

  previewMasterclass(course:Course, masterclass: Module): void {
    this.coursesService.setCurrentMasterclass(masterclass);
    this.coursesService.setBreadcrumb({'course': course.name, 'masterclass':masterclass.name});
    this.router.navigate(['course', course.slug, 'masterclasses', masterclass.slug, 'preview']);
  };

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
