import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";
import { NbMediaBreakpointsService, NbMenuService, NbThemeService, NbMenuItem } from '@nebular/theme';
import { takeUntil, map, distinctUntilChanged} from 'rxjs/operators' ;
import { Observable, Subject, combineLatest } from 'rxjs';
import { User, UserData } from 'app/@core/data/users';
import { CourseData } from 'app/@core/data/courses';
import { MENU_ITEMS, USER_MENU } from 'app/app-menu';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit, OnDestroy{

  private destroy$: Subject<void> = new Subject<void>();

  onlyPicture: boolean = true;
  showMenu:boolean = false;
  user$: Observable<User>;

  mainMenu:NbMenuItem[] = MENU_ITEMS;
  userMenu:NbMenuItem[] = USER_MENU;

  constructor(
    private menuService: NbMenuService,
    private router: Router,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private userService: UserData,
    private coursesService: CourseData,
  ) {
    this.userService.load();
  }

  ngOnInit() {
    combineLatest(
      [
        this.userService.isAdmin,
        this.coursesService.hasTygpro,
        this.coursesService.hasAces
      ]
    )
    .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe(([isAdmin, hasTygp, hasAces]:any) => {
        MENU_ITEMS[0].hidden = !hasAces;
        MENU_ITEMS[1].hidden = !hasTygp;
        MENU_ITEMS[4].hidden = !isAdmin;
        this.mainMenu = MENU_ITEMS;
    });
    this.user$ = this.userService.user;
    // this.mainMenu = this._menuService.menuItems;
    const { sm } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange().pipe(
      map(([, currentBreakpoint]) => currentBreakpoint.width < sm),
      takeUntil(this.destroy$),
    ).subscribe((isLessThanSm: boolean) => this.onlyPicture = !isLessThanSm);

    this.menuService.onItemClick()
    .pipe(takeUntil(this.destroy$))
    .subscribe((event) => {
      this.showMenu = false;
    });
  }

  goToProfile() :void
   {
    this.router.navigate(['courses/my-profile']);
  }

  toggleSidebar(): boolean
  {
    this.showMenu = !this.showMenu;
    return false;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
