import { NbMenuItem } from '@nebular/theme';

export const MENU_ITEMS: NbMenuItem[] =  [
  {
    title: 'ACES',
    icon: 'pie-chart-outline',
    link: '/courses/aces/overview',
    hidden:true
  },
  {
    title: 'TYG Pro',
    icon: 'clipboard-outline',
    link: '/courses/tyg-pro/overview',
    hidden:true
  },
  {
    title: 'My Courses',
    icon: 'checkmark-square',
    link: '/courses/my-courses',
  },
  {
    title: 'Help & Support',
    icon: 'question-mark-circle-outline',
    link: '/courses/support',
  },
  {
    title: 'Admin',
    icon: 'settings',
    url: '/admin/courses',
    hidden:true
  }
];

export const TYG_ITEMS: NbMenuItem[] =  [
  {
    title: 'TYG Pro',
    icon: 'clipboard-outline',
    link: '/courses/tyg-pro/overview'
  }
];

export const ACES_ITEMS: NbMenuItem[] =  [
  {
    title: 'ACES',
    icon: 'pie-chart-outline',
    link: '/courses/aces/overview',
  }
];

export const ADMIN_ITEMS: NbMenuItem[] =  [
  {
    title: 'Admin',
    icon: 'settings',
    url: '/admin/courses',
  }
];

export const USER_MENU: NbMenuItem[] =  [
  {
    title: 'My Account',
    link: './courses/my-profile'
  },
  {
    title: 'Log out',
    link:'./auth/logout'
  }
]
