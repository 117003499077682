import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiResponse } from 'app/@core/data/api-response';
import { StudentsData } from '../data/students';
import { Observable } from 'rxjs';

@Injectable()
export class StudentsService extends StudentsData {

  baseUrl: string = environment.baseUrl + 'courses/';

  constructor(private http: HttpClient) {super()}

  /* checked and commented : Method not in use */
  getStudents(): Observable<ApiResponse>
  {
    return this.http.get<ApiResponse>(this.baseUrl + "students/all");
  }

}
