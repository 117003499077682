import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({ name: 'filterByDateRange', standalone:true })
export class MomentDateRangePipe implements PipeTransform {
  transform(items : any, key:string, date: Date[]): any {
    if(date[0] && date[1]) {
      return items.filter((item: any) => moment(item[key]).isBetween(date[0], date[1]));
    }
    if(date[0]) {
      return items.filter((item: any) => moment(item[key]).isAfter(date[0]));
    }
    return items;
  }
}
