<div class="alert alert-info py-4 text-center" *ngIf="days > 14 && !this.isSkipped && hasNotPaid(course.course_enddate)">
  <!--  *ngIf="days > 14 && !this.isSkipped && course.course_enddate != '2031-12-31 23:59:59'"  -->
  <h3 class="mb-0"><button type="button" name="button" class="btn btn-link p-0" (click)="openManual()">Click here if you're ready to "pay what you can" to retain full, ongoing access to the Course Freedom Formula.</button></h3>
</div>
<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card class="nb-card-pwyc">
    <nb-card-header>
      <h1 class="text-center w-100 mb-0">
        Choose Your Pay-What-You-Can <br class="d-none d-sm-block">Amount for <strong>{{title}}</strong>
      </h1>
    </nb-card-header>
    <nb-card-body #dialogDiv class="layout-padding">
      <div class="row mx-0">
        <div class="col-12">
          <div [ngVideo]="video" class="mb-4" (videoOutput)='videoOutput($event)'></div>
          <h3 *ngIf="!watchedVideo$.value" class="text-center"><strong>Watch to the end of the video to access the payment form.</strong></h3>
        </div>
        <div class="col-12 slidein" *ngIf="watchedVideo$ | async">
          <div class="d-block slidein" *ngIf="showMessage">
            <h3><strong>How it works:</strong></h3>
            <div class="d-block">
              <ul class="mb-4 ps-4">
                <li>Choose your pay-what-you-can amount to retain full, ongoing access to the Course Freedom Formula.</li>
                <li>You will make four (4) payments of the same amount, with one payment per month. (The minimum is $10 per payment, until June 30.)</li>
                <li>Whatever amount you choose is perfect! There is no catch. :-)</li>
                <li>Please pay what you can <em>afford</em> right now (<em>not</em> more).</li>
                <li>Please pay an amount that <em>justifies the value you expect to gain from the program</em>. There is no upper limit.</li>
                <li>If you’re not sure what amount to put, the suggested amount is $375 per payment (which is $1,500 total) based on the retail value of this program.</li>
                <li>Remember: We guarantee you’ll make at least $2,000 in revenue by the end of the Course Freedom Formula program, so please keep that promise in mind as you’re selecting your pay-what-you-can amount.</li>
              </ul>
              <p><strong><strong>IMPORTANT: You have until June 30, 2024</strong> to choose your pay-what-you-can amount. If we don’t hear from you before that deadline, you’ll lose access to the course. And to restore your access, you'll need to commit to 4 payments of $375</strong>.</p>
              <p><strong>Now, enter your payment amount into the box below – NOTE: <em>you will make four (4) payments of whatever amount you enter, with one payment per month</em>. Next, check the box to confirm your understanding, and click the button to send your payment confirmation to us.</strong></p>
            </div>
          <form [formGroup]="pwycForm" (ngSubmit)="onFormSubmit()" autocomplete="off">
              <div class="mb-4">
                <!-- <h4>Median monthly payment selected is $500.50</h4> -->
                <div class="input-group my-4">
                  <label for="" class="d-block d-md-none">I choose to make 4 monthly payments of $</label>
                  <div class="input-group-prepend d-none d-md-block">
                    <span class="input-group-text" id="basic-addon3">I choose to make 4 monthly payments of $</span>
                  </div>
                  <input nbInput type="number" min="10" step="10" id="pwyc" name="pwyc" class="form-control" placeholder="Enter Amount (e.g. $375)" formControlName="pwyc" required="true"
                    [status]="f.pwyc.dirty ? (f.pwyc.invalid  ? 'danger' : 'success') : 'basic'"
                    [attr.aria-invalid]="f.pwyc.invalid && f.pwyc.touched ? true : null"
                    [attr.readonly]="days > 27"
                    [ngClass]="{ 'is-invalid': f.pwyc.invalid && f.pwyc.touched  }"/>
                  <div *ngIf="f.pwyc.touched && f.pwyc.invalid" class="invalid-feedback">
                    <div *ngIf="f.pwyc.errors?.required">This is required field</div>
                    <div *ngIf="f.pwyc.errors?.pattern">Please enter valid amount</div>
                    <div *ngIf="f.pwyc.errors?.min">This can not be empty or less then $10</div>
                    <!-- , there's a minimum amount of $10/month ($40 total) -->
                  </div>
                </div>
              </div>
              <div class="mb-4 form-check pb-3 pt-4 ps-0">
                <nb-checkbox [status]="f.accept.touched && f.accept.errors ? 'danger' : 'basic'" formControlName="accept">I understand that I am committing to 4 monthly payments of the amount above, and that my first payment will be drawn this week.</nb-checkbox>
                <!-- <input class="form-check-input" type="checkbox" name="accept" formControlName="accept" id="acceptCheck" required="true" [ngClass]="{ 'is-invalid': f.pwyc.invalid && f.accept.touched }" required>
                <label class="form-check-label ms-2" for="acceptCheck"  [ngClass]="{ 'is-invalid': f.accept.touched && f.accept.errors }">
                  I understand that I am committing to {{[62,64,65,66].indexOf(course?.id) !== -1 ? 6 : 4}} equal monthly payments and that my first payment will be drawn this week.
                </label> -->
                <div *ngIf="f.pwyc.invalid && f.accept.touched" class="invalid-feedback">
                  <div *ngIf="f.accept.errors.required">You must agree before submitting.</div>
                </div>
              </div>
              <div class="mb-4 alert alert-danger" *ngIf="errorMsg">
                <div class="mb-0" [innerHTML]="errorMsg | sanitizeHtml:[]"></div>
              </div>
              <div class="mb-4">
                <button type="submit" nbButton type="submit" class="mb-4 d-block me-2" status="primary" [disabled]="!pwycForm.valid || (pwycForm.valid && submitted)" [nbSpinner]="pwycForm.valid && submitted" nbSpinnerStatus="info" nbSpinnerSize="large" nbSpinnerMessage="">Confirm My Choice!<nb-icon icon="arrow-ios-forward-outline" class="ms-1"></nb-icon></button>
                <button *ngIf="!isSkipped" type="button" (click)="onSkip()" class="btn btn-link m-0 p-0">I’m not ready to decide yet – ask me again later.</button>
                <button *ngIf="isManual" type="button" (click)="dialogRef.close()" class="btn btn-link m-0 p-0">Cancel</button>
                <!-- <button (click)="confirmOpen(confirmdialog)" class="btn btn-warning m-0 p-0">confirmOpen</button> -->
              </div>
            </form>
          </div>
          <div class="alert alert-info" *ngIf="!showMessage && msg">
            <div class="mb-0" [innerHTML]="msg | sanitizeHtml:[]"></div>
            <button type="button" nbButton class="my-4 me-2" status="info" (click)="closeDialog()">Continue to {{title}}</button>
            <span class="text-muted">Closes in {{counter}}</span>
          </div>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</ng-template>
<ng-template #confirmdialog let-data let-ref="confirmRef">
  <nb-card class="nb-card-pwyc">
    <nb-card-body class="text-center">
      <h1 class="text-center w-100 mb-4">
        You've chosen to make 4 monthly payments of ${{pwycForm.get('pwyc').value}}
      </h1>
      <button type="button" nbButton class="me-2" status="info" (click)="updatePayment()">Yes, I Confirm My Choice!<nb-icon icon="arrow-ios-forward-outline" class="ms-1"></nb-icon></button>
      <button (click)="confirmClose()" type="button" class="btn btn-default m-0 p-0">Cancel</button>
    </nb-card-body>
  </nb-card>
</ng-template>
