import { Component, OnDestroy, OnInit, OnChanges, Input, SimpleChanges} from '@angular/core';
import { Router, ActivatedRoute, Params} from "@angular/router";
import { NbMediaBreakpointsService, NbThemeService } from '@nebular/theme';
import { takeUntil, map, distinctUntilChanged } from 'rxjs/operators' ;
import { Subject, Observable } from 'rxjs';
import { Course, Module, CourseData } from 'app/@core/data/courses';
import { Industry, UserData } from 'app/@core/data/users';
import { Directory, DirectoryFilter, DirectoryData } from 'app/@core/data/directory';
import { QueryParams } from 'app/@core/data/master';
import { ShufflePipe, FilterByPipe } from 'ngx-pipes';
import * as moment from 'moment-timezone';

@Component({
  selector: 'ngx-peeps-find',
  styleUrls: ['./peeps.component.scss'],
  templateUrl: './peeps.component.html',
  providers: [ShufflePipe, FilterByPipe]
})

export class PeepsFindComponent implements OnInit, OnChanges, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @Input() heading:string;
  @Input() course: Course;
  @Input() style?:string;
  @Input() col?:number = 1;
  @Input() showcount?:string;
  @Input() filter: DirectoryFilter = {key:'course_id', value:1, limit:2, truncate:3};
  @Input() search?:string = '';

  public filer$ = new Subject<[]>();

  isSm:boolean = false;
  loading:boolean = true;
  params:QueryParams = {};
  peeps$: Observable<Directory[]>;

  count:number=0;
  industry:Industry;
  industries:Industry[]= [];

  searchField:string;
  filterBy:any=[];
  activedirectory:string = 'all';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private coursesService: CourseData,
    private userService : UserData,
    private directoryService: DirectoryData,
    private shufflePipe: ShufflePipe,
    private filterByPipe: FilterByPipe
  ) {
    this.route.params
    .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
    .subscribe((params:Params) => {
      this.params = params;
    });
    this.industries = this.userService.getIndustries();
    this.filer$.subscribe(val => {this.filterBy = val})
  }

  ngOnInit() {
    const { sm } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange().pipe(
      map(([, currentBreakpoint]) => currentBreakpoint.width < sm),
      takeUntil(this.destroy$),
    ).subscribe((isLessThanSm: boolean) => this.isSm = !isLessThanSm);
    this.getPeeps();
  }

  ngOnChanges(changes: SimpleChanges):void
   {
    if(changes.search) {
      this.getPeeps();
    }
  }

  getPeeps(): void
  {
    this.peeps$ = this.directoryService.directory
    .pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$),
      map((directory:Directory[]) => {
          let directoryfilter:Directory[] = [];
          if(this.filter.key == 'industry_id') {
            this.industries = this.userService.getIndustries();
            this.industry = this.industries.filter((industry:Industry) => industry.id == this.filter.value)[0];
            directoryfilter = directory.filter((peep:Directory) => peep['industry_id'] == this.filter.value);
          } else if(this.filter.key == 'timezone'){
            directoryfilter = directory.filter((peep:Directory) => peep.timezone == this.filter.value);
          } else if(this.filter.key == 'new_member') {
            directoryfilter = directory.sort((a, b) => moment(b.created).valueOf() - moment(a.created).valueOf());
          } else if(this.filter.key == 'masterclass') {
            directoryfilter = directory.filter((peep:Directory) => {
              return peep.masterclasses.find((item:Module) => item.id === this.filter.value);
            });
          } else if(this.filter.key == 'random') {
            directoryfilter = this.shufflePipe.transform(directory);
          } else if(this.filter.key == 'similar_interest') {
            let masterclass = this.course.masterclasses.filter((masterclass:Module) => masterclass.status == 1 && masterclass.is_owned == 1 );
            directoryfilter = directory.filter( (peep:Directory) => {
              return peep.industry_id == this.filter.value || peep.masterclasses.find((item:Module) => masterclass.includes(item));
            });
          } else {
            directoryfilter = directory;
          }
          if(this.search){
            directoryfilter = this.filterByPipe.transform(directoryfilter, ['name', 'email','company','industry','timezone'], this.search);
          }
          if(this.filter.limit != -1) {
            let limit = !this.isSm ? this.filter.truncate : this.filter.limit;
            this.count = directoryfilter.length - limit;
            return directoryfilter.slice(0, limit);
          } else {
            return directoryfilter;
          }
      })
    );
  }

  getArray(limit:number) :number[]
  {
    return Array.from({length: limit}, (v, i) => i)
  }

  // showDirectory(directory:Directory, value:string) {
  //   this.activedirectory = directory;
  //   this.searchField = value;
  // }

  setFilter(key:string, value:any) :void
  {
    const filterkey = value ? key : 'all';
    this.filter = {key:filterkey, value:value, limit:-1, truncate:-1}
    this.getPeeps();
  }

  getStateCountry(data:Directory) :string
  {
    let sc = [data.state, data.country];
    let scs = sc.filter(function (val) {return val != '';});
    return scs.length > 0 ? scs.join(', ') : 'None';
  }

  navigateTo(course:Course, slug:string):void
  {
    this.router.navigate(['course', course.slug, 'directory', slug]);
  };

  goToPage(course:Course, slug:string) {
    if(this.filter.key == 'masterclass') {
      this.router.navigate(['course', course.slug, 'directory', 'strategy', slug]);
    } else if(this.filter.key == 'timezone') {
      this.router.navigate(['course', course.slug, 'directory', 'timezone']);
    } else if(this.filter.key == 'industry_id') {
      this.router.navigate(['course', course.slug, 'directory', 'industry', slug]);
    } else  {
      this.router.navigate(['course', course.slug, 'directory']);
    }
  }

  navigateToAll(course:Course, slug:string): void
  {
    this.router.navigate(['course',course.slug, 'directory']);
  }

  goToProfile(directory:Directory) :void
  {
    this.coursesService.setBreadcrumb({'peep':directory.name});
    this.router.navigate(['course', this.params.course, 'directory', 'profile', directory.id]);
  }

  trackByPeeps(index:number, Item:Directory) :number
  {
    return index;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
