import { Injectable } from '@angular/core';
import { CanActivate, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { CourseData } from 'app/@core/data/courses';

@Injectable()
export class AuthGuard implements CanActivate, Resolve<any> {
  private redirect:string = '';

  constructor(
    private router: Router,
    private authService: NbAuthService,
    private coursesService:CourseData
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.redirect = state.url;
    return this.authService.isAuthenticated()
    .pipe(
      tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['auth/login'], { queryParams: { redirect: this.redirect } });
        }
      }),
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.coursesService.loadAll()
    return true;
  }
}
