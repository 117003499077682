import { Observable } from 'rxjs';
import { ApiResponse } from 'app/@core/data/api-response';

export interface Category {
  id:number|null;
  name:string;
  slug:string;
  description:string;
  parent:number;
  children?:Category[];
  created_date:string|null;
}

export interface Newsfeed {
  id:number|null;
  course_id:number;
  title:string;
  subtitle:string;
  description:string;
  link:string;
  icon:string;
  color:string;
  date:Date|null;
  datetime:Date;
  created_by:number|null;
  created_date:Date|null;
}

export interface LibraryTag {
    text?: string;
    color?: string;
}

export interface LibraryVideo {
  id: string;
  type: string;
  title: string;
  duration: string;
  actions: any; //change this to type
}

export interface LibraryDownloads {
  color: string;
  link: string;
  text: string;
  type: Object;
}

export interface LibraryOptions {
  image: string;
  cta: boolean;
  tag:LibraryTag | null,
  video: LibraryVideo,
  downloads: LibraryDownloads[]
}

export interface Author {
  id:number;
  name?:string;
  firstname?:string;
  lastname?:string;
  avatar?:string;
}

export interface Library {
  id:number|null;
  course_id?:number;
  cat_id:number;
  cat_slug?:string;
  category?:string;
  slug:string;
  type:string;
  name:string;
  description:string;
  content:string;
  is_featured:boolean;
  start_date: Date;
  end_date?:string;
  options:LibraryOptions;
  status:number|null;
  created_by?:number|null;
  created_date:Date|null;
  author: Author
}

export abstract class LibraryData {
  abstract library:Observable<Library[]>;
  abstract category:Observable<Category[]>;
  abstract newsfeed:Observable<Newsfeed[]>;
  abstract load():void;
  abstract getCategory():void;
  abstract getPost(slug: string):Observable<ApiResponse>;
  abstract unload():void;
  abstract nestData(items:Category[], parent:any):Category[];
}
