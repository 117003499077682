<div *ngIf="faqs.length > 0">
  <div class="row mx-0 justify-content-center layout-padding">
    <div class="col-12 col-md-6 mb-2">
      <nb-accordion class="faqs">
        <nb-accordion-item class="border mb-3" *ngFor="let faq of faqs.slice(0,limit)">
          <nb-accordion-item-header class="p-3 me-2">
            <div  [innerHTML]="faq.question"></div>
          </nb-accordion-item-header>
          <nb-accordion-item-body class="px-0">
            <div [innerHTML]="faq.answer"></div>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </div>
  </div>
</div>
