import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'safeLink' })
export class SafeLinkPipe implements PipeTransform {

  transform(url: string): string {

    if(url == '' || url == null)
      return url;
    if (!/^https?:\/\//i.test(url))
      return 'http://'+url;
    return url;
  }
}
