import { Component, OnDestroy, OnInit, OnChanges, Input, SimpleChanges} from '@angular/core';
import { takeUntil } from 'rxjs/operators' ;
import { Subject } from 'rxjs';
import { Faq, FaqData } from 'app/@core/data/faqs';
import { Course } from 'app/@core/data/courses';
import { User } from 'app/@core/data/users';

export interface FaqFilter {
  key: string;
  value: any;
  limit: number;
  truncate?:number;
}

@Component({
  selector: 'ngx-course-faq',
  styleUrls: ['./faq.component.scss'],
  templateUrl: './faq.component.html',
})

export class FaqComponent implements OnInit, OnChanges, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();

  @Input() course: Course;
  @Input() filter: FaqFilter|null;

  user: User;
  faqs:Faq[] = [];
  faq: Faq;
  limit:number = 10;
  total:number = 0;
  loading:boolean = true;

  constructor(
    private faqService: FaqData,
  ) {}

  ngOnInit() {
  }

  ngOnChanges(change: SimpleChanges) {
    // this.filter = this.change.filter.currentValue;
    this.getFaqs();
  }

  getFaqs() :void
  {
    this.faqService.faqs
    .pipe(takeUntil(this.destroy$))
    .subscribe((result:Faq[]) => {
      this.loading = false;
      if(result.length > 0) {
        if(this.filter.key == 'limit') {
          this.faqs = result.slice(0,this.filter.value);
        } else if(this.filter.key == 'module') {
          this.faqs = result.filter((faq:Faq) => faq.module_id === this.filter.value);
        } else if(this.filter.key == 'lesson') {
          this.faqs = result.filter((faq:Faq) => faq.lesson_id === this.filter.value);
        } else if(this.filter.key == 'user') {
          this.faqs = result.filter((faq:Faq) => faq.user_id === this.user.id);
        } else {
          this.faqs = result;
        }
        this.total = this.faqs.length;
      }
    });
  }

  loadMore(limit:number) {
    this.limit = limit;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
