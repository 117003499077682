import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngx-heading',
  template: `
    <div class="d-flex align-items-top mb-4">
          <nb-icon [icon]="icon" status="info" pack="mirasee" class="flex-shrink-0 fs-3"></nb-icon>
          <div class="flex-grow-1 ms-3 align-self-center">
            <h2 [ngClass]="{'mb-0': !caption}"><ng-content></ng-content></h2>
            <p class="text-disabled mt-2 mb-0" *ngIf="caption">{{caption}}</p>
          </div>
        </div>
    `,
})
export class HeadingComponent {
  @Input() caption?:string;
  @Input() icon?:string;
}
