<div class="w-100">
  <h6 class="text-uppercase text-disabled mt-4">Helpful Materials</h6>
  <ul class="list-group list-group-flush">
    <li class="list-group-item px-0" *ngFor="let action of actions; let $i = index">
      <a (click)="actionClick(action, $i)" class="pointer">
        <nb-icon [icon]="action.icon" status="primary" pack="mirasee" class="me-2"></nb-icon>{{action.text}}
      </a>
    </li>
  </ul>
</div>
