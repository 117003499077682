import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value: string, args: string[]): any {
    let emptytag = args.length > 0 && args[0] == 'emptytag' ? true : false;
    if(emptytag) {
      value.replace('<p[^>]*>[\s|&nbsp;|<br>]*<\/p>','').trim();
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
