import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { of as observableOf,  Observable,  BehaviorSubject } from 'rxjs';
import { Faq, FaqData } from '../data/faqs';
import { ApiResponse } from 'app/@core/data/api-response';

@Injectable()
export class FaqService extends FaqData {
  baseUrl: string = environment.baseUrl + 'courses/faq/';

  private _faqs = new BehaviorSubject<Faq[]>([]);
  private dataStore: { faqs: Faq[] } = { faqs: [] };
  readonly faqs = this._faqs.asObservable();

  constructor(private http: HttpClient) {super()}

  loadAll(course_id: number) :void
  {
    this.http.get<ApiResponse>(this.baseUrl + course_id + '/all').subscribe({
      next: (result:ApiResponse) => {
        if(result.status == 'ok') {
          this.dataStore.faqs = result.data;
          this._faqs.next(Object.assign({}, this.dataStore).faqs);
        }
      },
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  unload() :void
  {
    this.dataStore.faqs = [];
    this._faqs.next(Object.assign({}, this.dataStore).faqs);
  }

  load(id: number) :void
  {
    this.http.get<ApiResponse>(this.baseUrl + id).subscribe({
      next: (result:ApiResponse) => this.updateFaqStore(result),
      error : (e) => console.log(e),
      complete: () => console.log('complete')
    });
  }

  private updateFaqStore(result:ApiResponse):void
  {
    if(result.status == 'ok') {
      let notFound = true;
      let data = result.data;
      this.dataStore.faqs.forEach((item, index) => {
        if (item.id === data.id) {
          this.dataStore.faqs[index] = data;
          notFound = false;
        }
      });
      if (notFound) {
        this.dataStore.faqs.push(data);
      }
      this._faqs.next(Object.assign({}, this.dataStore).faqs);
    }
  }

  getLessonFaq(id: number): Observable<Faq[]> {
    const faqs = this.dataStore.faqs.filter(faq => faq.lesson_id === id);
    return observableOf(faqs);
  }

}
