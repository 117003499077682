import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  NbAlertModule,
  NbAccordionModule,
  NbActionsModule,
  NbBadgeModule,
  NbDatepickerModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbInputModule,
  NbCardModule,
  NbListModule,
  NbPopoverModule,
  NbSelectModule,
  NbIconModule,
  NbSpinnerModule,
  NbThemeModule,
  NbTooltipModule,
  NbProgressBarModule,
  NbCheckboxModule,
} from '@nebular/theme';

import { NbSecurityModule } from '@nebular/security';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgPipesModule } from 'ngx-pipes';
import { DpDatePickerModule } from 'ng2-date-picker';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';
import { LineTruncationLibModule, LineTruncationDirective } from './modules/line-truncation/line-truncation.module';

import {
  ActionComponent,
  AssessmentComponent,
  CoachContentComponent,
  BurgerMenuComponent,
  EventComponent,
  FaqComponent,
  FooterComponent,
  HeaderComponent,
  HeaderCourseComponent,
  HeadingComponent,
  JumbotronComponent,
  LessonListComponent,
  MasterclassComponent,
  ActionPlanComponent,
  PeepsFindComponent,
  PeerReviewResultComponent,
  PlaceholderComponent,
  PwycComponent,
  PwycCFFComponent,
  QAComponent,
  VideoActionsComponent,
} from './components';

import {
  ngActivityDirective,
  ngExportDirective,
  ngHrefToRouterLinkDirective,
  ngHsformDirective,
  ngVideoDirective,
  ngIframeAutoHeightDirective,
} from './directives';

import {
  CapitalizePipe,
  HighlightPipe,
  MomentFormatPipe,
  NumberWithCommasPipe,
  PluralPipe,
  RoundPipe,
  SafeEmbedPipe,
  SafeLinkPipe,
  SanitizeHtmlPipe,
  TimingPipe,
  TruncatePipe,
  TruncateObjectPipe,
} from './pipes';

import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';

import { DEFAULT_THEME } from './styles/theme.default';

const NB_MODULES = [
  NgOptimizedImage,
  NbAlertModule,
  NbAccordionModule,
  FormsModule,
  ReactiveFormsModule,
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbBadgeModule,
  NbDatepickerModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbEvaIconsModule,
  NbButtonModule,
  NbInputModule,
  NbCardModule,
  NbListModule,
  NbPopoverModule,
  NbSelectModule,
  NbIconModule,
  NbSpinnerModule,
  NbProgressBarModule,
  NbTooltipModule,
  NbCheckboxModule,
  DpDatePickerModule,
  NgPipesModule,
  BreadcrumbComponent,
  BreadcrumbItemDirective,
  LineTruncationLibModule
];
const COMPONENTS = [
  HeaderComponent,
  HeaderCourseComponent,
  HeadingComponent,
  BurgerMenuComponent,
  JumbotronComponent,
  ActionComponent,
  ActionPlanComponent,
  AssessmentComponent,
  CoachContentComponent,
  LessonListComponent,
  MasterclassComponent,
  EventComponent,
  FaqComponent,
  FooterComponent,
  PeepsFindComponent,
  PeerReviewResultComponent,
  PlaceholderComponent,
  PwycComponent,
  PwycCFFComponent,
  QAComponent,
  VideoActionsComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent
];

const DIRECTIVES = [
  ngActivityDirective,
  ngExportDirective,
  ngHrefToRouterLinkDirective,
  ngHsformDirective,
  ngVideoDirective,
  ngIframeAutoHeightDirective,
]

const PIPES = [
  CapitalizePipe,
  HighlightPipe,
  MomentFormatPipe,
  NumberWithCommasPipe,
  PluralPipe,
  RoundPipe,
  SafeEmbedPipe,
  SafeLinkPipe,
  SanitizeHtmlPipe,
  TimingPipe,
  TruncatePipe,
  TruncateObjectPipe,
];

// const ENTRY_COMPONENTS = [
//   QAComponent,
// ];

@NgModule({
  imports: [CommonModule, RouterModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS, ...DIRECTIVES, LineTruncationDirective],
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
})

export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'mirasee',
          },
          [ DEFAULT_THEME],
        ).providers,
      ],
    };
  }
}