import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateobject'
})

export class TruncateObjectPipe implements PipeTransform {

  transform(object:any, args: string[]): string {
    let length = object.length;
    let limit = args.length > 0 ? parseInt(args[0]) : 2;
    let subtext = '';
    let sobject:object;
    if(length <= limit) {
      sobject = object;
    } else if(limit < length) {
      sobject = object.slice(0,limit);
      subtext = (length - limit) + ' more';
    }
    if(sobject) {
      return Array.prototype.map.call(sobject, function(item) { return item.name; }).join(", ") + ' ' +subtext;
    }
    return '';
  }
}
