import { Observable } from 'rxjs';
import { ApiResponse } from 'app/@core/data/api-response';

export interface DirectoryFilter {
  key: string;
  value: any;
  limit: number;
  truncate?:number;
}

export interface Directory {
  id:number;
  name: string;
  email: string;
  company:string;
  city: string;
  state: string;
  zip: string;
  country: string;
  timezone: string;
  avatar: string;
  bio: string;
  website: string;
  industry_id:number;
  industry:string;
  created:string;
  meta?:any;
  masterclasses?:any;
}

export abstract class DirectoryData {
  abstract directory:Observable<Directory[]>;
  abstract loadAll(course_id: number):void;
  abstract load(id: number):Observable<ApiResponse>;
  abstract getMentions(id: number):Observable<ApiResponse>;
  abstract unload():void;
}
