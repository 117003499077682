<nb-list *ngIf="reviews.length != 0">
  <nb-list-item class="border-0 px-0" *ngFor="let hw of reviews.homework; let i = index">
    <label class="fw-bold mb-2" [innerHTML]="hw.label"></label>
    <p [innerHTML]="hw.value"></p>
    <blockquote class="blockquote" *ngFor="let pr of reviews.reviews; let j = index">
      <div class="callout ms-2" [ngClass]="{'callout-basic': j == 0,  'callout-info': j == 1,  'callout-primary': j == 2}" *ngIf="pr[i] && pr[i].type != 'none'">
        <p [innerHTML]="pr[i].value"></p>
      </div>
    </blockquote>
  </nb-list-item>
</nb-list>
<div *ngIf="errormsg">
  <nb-alert outline="danger">{{errormsg}}</nb-alert>
</div>
