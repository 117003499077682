import { Observable } from 'rxjs';
import { ApiResponse } from 'app/@core/data/api-response';

export interface Assessment {
  id:number;
  score:number;
  assessment:string[];
}

export interface CoachContent {
  video:string;
  text:string;
  firstname:string;
  lastname:string;
  email:string;
}

export interface FieldOption {
  key?:any;
  label?:any;
  score?:any;
}

export interface FieldRule {
  random:any;
  maxChar:any;
  minChar:any;
  disabled:boolean;
}

export interface  BasicField {
  type:string;
  label:string;
  icon?:string;
  description:string;
  placeholder:string;
  required:boolean;
  options: [] | FieldOption[];
}

export interface Field {
  id?:number;
  form_id:number;
  type:string;
  name:string;
  label:string;
  required:boolean;
  placeholder:string;
  description:string;
  options:FieldOption[] | [];
  rules:FieldRule;
  status:number;
  created_date:string;
  value:string;
}

export interface FormButton {
    text:string;
    class: string;
    resubmit:boolean;
}

export interface FormOption {
  btn:FormButton;
  msg:string;
  redirect:boolean;
  assessment: boolean;
  peer_homework: boolean;
  slack: any;
  fortune: boolean;
}

export interface Form {
  id: number;
  title: string;
  description:string;
  start_date:Date;
  end_date:Date;
  options?:FormOption;
  status: number;
  created_date: Date;
  entry_id:number;
  score:number;
  submitted: null | number;
  entry_status:number;
  fields:Field[];
}

export abstract class FormData {
  abstract load(form_id:number): Observable<ApiResponse> ;
  abstract loadReview(review:number, form_id:number): Observable<ApiResponse> ;
  abstract getFormEntry(form_id:number): Observable<ApiResponse>;
  abstract getAssessment(form_id:number): Observable<ApiResponse>;
  abstract getCoachContent(form_id:number): Observable<ApiResponse>;
  abstract getFortune(entry_id:number): Observable<ApiResponse>;
  abstract getReviews(form_id:number): Observable<ApiResponse>;
  abstract saveFormEntry(form_id:number, entry:any):Promise<ApiResponse>;
  abstract saveSlackFormEntry(channelid:string, form_id:number, entry:any):Promise<ApiResponse>;;
  abstract saveReviewEntry(review:number, form_id:number, entry:any):Promise<ApiResponse>;;
  abstract autoSaveFormEntry(form_id:number, fields:any): Observable<ApiResponse> ;
  abstract getFormTableEntry(form_id:number, field_id:number): Observable<ApiResponse>;
  abstract saveFormTableEntry(form_id:number, entry:any):Observable<ApiResponse>;
  abstract updateFormTableEntry(id:number, entry:any):Observable<ApiResponse>;
  abstract updateFortune(segment:number):Observable<ApiResponse>;
}
