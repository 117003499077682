import { Observable } from 'rxjs';
import { ApiResponse } from 'app/@core/data/api-response';
import { Author, LibraryOptions } from './library';

export interface Category {
  id:number|null;
  name:string;
  slug:string;
  description:string;
  parent:number;
  children?:Category[];
  created_date:string|null;
}

export interface Library {
  id:number|null;
  course_id?:number;
  cat_id:number;
  cat_slug?:string;
  category?:string;
  slug:string;
  type:string;
  name:string;
  description:string;
  content?:string;
  is_featured:boolean;
  start_date:Date;
  end_date?:Date;
  options:LibraryOptions;
  status:number|null;
  created_by?:number|null;
  created_date:Date|null;
  author:Author
}

export abstract class ResourceLibraryData {
  abstract library:Observable<Library[]>;
  abstract category:Observable<Category[]>;
  abstract buzzcategory:Observable<Category[]>;
  abstract load() :void;
  abstract getCategory() :void;
  abstract getBuzzCategory() :void;
  abstract getPost(slug: string): Observable<ApiResponse>;
  abstract unload():void;
  abstract nestData(items:Category[], parent:number):Category[];
}
