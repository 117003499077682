import { Observable } from 'rxjs';
import { Lesson } from 'app/@core/data/courses';

export interface ProgresItem {
    id: number;
    status:string;
    start_date: Date|string;
    end_date: Date;
}

export interface Progress {
  'nextsteps':{
    [key:string]: ProgresItem
  };
  'modules':{
    [key:string]: ProgresItem
  };
  'masterclasses':{
    [key:string]: ProgresItem
  };
  'lessons':{
    [key:string]: ProgresItem
  };
}

export abstract class ProgressData {
  abstract progress: Observable<Progress>;
  abstract load(cid: number) :void;
  abstract unload():void;
  abstract getModuleLessonProgress(lessons:Lesson[]):number;
  abstract markLessonStarted(cid: number, lid: number) :Promise<boolean>;
  abstract markLessonComplete(cid: number, lid: number) :Promise<boolean>;
  abstract markMasterClassLessonStarted(cid: number, lid: number) :Promise<boolean>;
  abstract markMasterClassLessonComplete(cid: number, lid: number) :Promise<boolean>;
}
