import { Component, OnInit, Input} from '@angular/core';
import { Router } from "@angular/router";

@Component({
  selector: 'ngx-video-actions',
  templateUrl: './video-actions.component.html',
})

export class VideoActionsComponent implements OnInit {

  @Input() actions:any;

  constructor(
    private router: Router,
  ) {}

  ngOnInit() {
  }

  actionClick(action:any, i:number):boolean {
    if(action.type == 'rating') {
      this.actions[i].icon = this.actions[i].icon == 'star' ? 'star-outline' : 'star';
    } else if(action.type == 'link') {
      this.router.navigate([action.link]);
    } else {
      window.open(action.link,'_blank');
    }
    return true;
  }

}
