import { Injectable, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'environments/environment';

import { filter, delay, map } from 'rxjs/operators';
import { NB_WINDOW } from '@nebular/theme';
declare const gtag: any;

@Injectable()
export class AnalyticsService {
  private enabled: boolean;

  constructor(
    @Inject(NB_WINDOW) private window:any,
    private location: Location,
    private router: Router
  ) {
    if(environment.gtm) {
      this.addGAScript();
    }
    this.enabled = environment.gtm;
  }

  trackPageViews() :void
  {
    if (this.enabled) {
      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.location.path()),
        filter((location: string) => this.trackLocation(location)),
        delay(50),
      )
      .subscribe((location: string) => this.gtmPushToDataLayer({event: 'pageView' , path: location}));
    }
  }

  trackEvent(params:any) :void
  {
    if (this.enabled) {
      this.gtmPushToDataLayer(params);
    }
  }

  private trackLocation(path: string) {
    if (path.match(/\/components\/[a-zA-Z-]+\/?$/)
    || path.match(/\/docs\/?$/)
    || path.match(/\/example\//)) {

      return !!path.match(/\/components\/components-overview\/?$/);
    }
    return true;
  }

  private gtmPushToDataLayer(params:any):void
  {
    this.window.dataLayer.push(params);
  }

  /** Add Google Analytics Script Dynamically */
  addGAScript() :void
  {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.gaId;
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    gtag('config', environment.gaId, { send_page_view: false });
  }

}
