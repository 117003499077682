<div class="w-100 d-block">
  <ng-content></ng-content>
  <ng-template #loaingevent>
    <nb-alert outline="primary" class="w-100" #loaingevent>
      <ngx-placeholder type="event"></ngx-placeholder>
    </nb-alert>
  </ng-template>
  <div *ngIf="(events$ | async | filterBy: ['name','topic','event.options.location','link.text']: searchField) as events; else loaingevent">
    <nb-alert [outline]="outline" [status]="outline" [class]="[11,31].includes(event.course_id) ? 'w-100 dd': 'w-100 pointer dafa'" *ngFor="let event of events" (click)="viewEvent(event.id)">
      <div class="d-flex">
        <div class="flex-shrink-0">
          <nb-icon icon="calendar" pack="mirasee" size="medium" class="mt-1" *ngIf="filter.key != 'pastrecording'"></nb-icon>
          <nb-icon icon="play" pack="mirasee" status="primary" size="medium" class="mt-1" *ngIf="filter.key == 'pastrecording'"></nb-icon>
        </div>
        <div class="flex-grow-1 ms-3">
          <p class="mb-0" [innerHTML]="event.name | highlight: searchField"></p>
          <p class="text-disabled paragraph-2 mb-1">{{event.datetime | momentFormat:'LLLL'}} EST</p>
          <p class="mb-0 mt-2 event-link" *ngIf="event.options.location && filter.key != 'pastrecording' && filter.key != 'past'">Join here: <a href="{{event.options.location}}" target="_blank" class="btn-link text-underline" [innerHTML]="event.options.location | highlight: searchField"></a></p>
          <p class="mb-0 mt-2 event-topic" [innerHTML]="event.topic | highlight: searchField"></p>
        </div>
      </div>
      <p *ngIf="link && link.text" class="mb-0 ms-4 ps-1 mt-2"><a (click)="goToPage(course.slug,'events')" href="javascript:void(false)" class="pointer {{link.color}} paragraph" [innerHTML]="link.text | highlight: searchField"></a></p>
    </nb-alert>
    <nb-alert [outline]="outline" status="basic" class="w-100 bg-transparent" *ngIf="events.length == 0">
      <div class="d-flex">
        <div class="flex-shrink-0">
          <nb-icon icon="calendar" pack="mirasee" size="medium"></nb-icon>
        </div>
        <div class="flex-grow-1 ms-3">
          <p class="mb-0">No event</p>
        </div>
      </div>
      <p class="mb-0 ms-4 ps-1 mt-2" *ngIf="link"><a [routerLink]="[basepath,course.slug,'events']" class="pointer {{link.color}} paragraph">View All Events</a></p>
    </nb-alert>
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
